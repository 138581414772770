import { Stack } from '@mui/material';
import { Title } from '../../../../../ui/mui/Title/Title';
import { Group } from '../../../../../ui/mui/Group/Group';
import { DatePicker } from '../../../../date-picker/DatePicker';
import { SelectOutLabel } from '../../../../../ui/mui/Select/SelectOutLabel';
import { useDrivers } from '../../../../../hooks/Reqs/useDrivers';
import { useAddFormContext } from '../../../../../hooks/Context/useAddFormContext';
import { InputOutLabal } from '../../../../../ui/mui/Input/InputOutLabal';
import { useCallback } from 'react';

export const ExecuterSection = () => {
  const { updateFormData } = useAddFormContext();
  const { driverList, freeDrivers, vehicleInfo, handleChangeDriver } = useDrivers();

  const handleSelectDriver = useCallback(
    (value: string) => {
      if (!freeDrivers || freeDrivers.length === 0) {
        return;
      }

      const driver = freeDrivers.find((curr) => curr.full_name === value);
      const driver_id = driver ? driver.id : 0;
      handleChangeDriver(driver_id);
      updateFormData('driver')(driver_id);
    },
    [freeDrivers, handleChangeDriver, updateFormData]
  );

  return (
    <Stack gap={3}>
      <Title variant="h4">Исполнитель заявки</Title>
      <Group gap={140}>
        <Stack gap={1.2}>
          <label>Дата выполнения рейса</label>
          <DatePicker
            defaultValue=""
            onChange={(e) => updateFormData('req_date')(e?.format('YYYY-MM-DD'))}
            fz={14}
          />
        </Stack>
        <Group gap={40}>
          <SelectOutLabel
            label="Выберите водителя"
            list={driverList}
            w={300}
            placeholder="Выбрать"
            onChangeValue={handleSelectDriver}
          />
          <InputOutLabal label="Автомашина" disabled w={250} value={vehicleInfo} />
        </Group>
      </Group>
    </Stack>
  );
};
