import Modal from './Modal';

export default function ModalTableFields({
  isModalOpen,
  closeModal,
  state,
  tableFields,
  setState,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
  state: any;
  tableFields: any[];
  setState: Function;
}) {
  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <h2 className="modal-title">Настройки колонок</h2>

      <div className="table-settings d-flex flex-wrap justify-between">
        {tableFields.map((field: { mykey: string; label: string }) => (
          <label className="table-settings__item d-flex" key={field.mykey}>
            <input
              type="checkbox"
              className="table-settings__item-input"
              checked={!state[field.mykey]}
              onClick={() => {
                const tableFieldIsClosed2 = {
                  ...state,
                  [field.mykey]: !state[field.mykey],
                };
                localStorage.setItem('reqTableFields', JSON.stringify(tableFieldIsClosed2));
                setState(tableFieldIsClosed2);
              }}
            />
            <div className="table-settings__item-inner d-flex items-center justify-between">
              <span className="table-settings__item-title transition">{field.label}</span>

              <div className="table-settings__item-switch d-flex transition"></div>
            </div>
          </label>
        ))}
      </div>

      <button className="modal-button d-flex items-center transition" onClick={closeModal}>
        Сохранить
      </button>
    </Modal>
  );
}
