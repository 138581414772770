import { useEffect, useMemo, useState } from 'react';
import { myFetch } from '../../utils';
import SETTINGS from '../../settings';
import { useNavigate } from 'react-router-dom';
import { Service } from '../../interfaces/req';

export const useServiceSelect = () => {
  const navigate = useNavigate();
  const [serviceSelectData, setServiceSelectData] = useState({
    serviceCost: {},
    serviceList: [],
  });

  useEffect(() => {
    const fetchServices = async () => {
      const response = await myFetch(`${SETTINGS.SITE_URL}/api/v1/reqs/services/`, {}, navigate);
      if (!response.ok) return;

      const data = await response.json();

      const serviceData = {
        serviceCost: data.results.reduce((acc: Record<number, number>, curr: Service) => {
          acc[curr.id] = curr.cost;

          return acc;
        }, {}),
        serviceList: data.results.map((item: Service) => ({
          id: item.id,
          label: item.name,
        })),
      };
      setServiceSelectData(serviceData);
    };
    fetchServices();
  }, [navigate]);

  return useMemo(() => ({ serviceSelectData }), [serviceSelectData]);
};
