import { useState } from 'react';
import SETTINGS from '../../settings';
import MyForm from '../../components/MyForm';
import companyAddFields from '../../form-fields/company-add';

export default function CompanyAdd() {
  const baseCompany: any = {};
  const [company, setCompany] = useState(baseCompany);
  const baseErrors: any = {};
  const [errors, setErrors] = useState(baseErrors);
  return (
    <>
      <MyForm
        fields={companyAddFields}
        method="POST"
        returnUrl={`/companies/`}
        formUrl={`${SETTINGS.SITE_URL}/api/v1/companies/`}
        state={company}
        setState={setCompany}
        errors={errors}
        setErrors={setErrors}
      />
    </>
  );
}
