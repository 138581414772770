import { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Alert, InputAdornment, SelectChangeEvent, Snackbar, TextField } from '@mui/material';
import { YMaps, Placemark, Map } from 'react-yandex-maps';
import { Req } from '../interfaces/req';
import { Link, NavLink, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { myFetch } from '../utils';
import SETTINGS from '../settings';
import { getReqResult } from './reqs/ReqList';
import { Container } from '../interfaces/container';
import { Vehicle, WialonVehicleLocations } from '../interfaces/vehicle';
import { BallonContent } from '../components/map-components/BallonContent';
import { Select } from '../components/select/Select';

import { getFilteredMapContainers } from '../utils/getFilteredMapContainers';
import { BallonContentContainer } from '../components/map-components/BalloonContentContainer';
import { cubeType } from '../params/container';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ru } from 'date-fns/locale';
import { getDateOrder } from '../utils/getDateOrder';
import { getDateFormat } from '../utils/getDateFormat';
import { useWialonVehiclesLocation } from '../hooks/Wialon/useWialonVehiclesLocation';
import { SNACKBAR_CONFIG } from '../constants/constants';
import { useDisclosure } from '../hooks/useDisclosure';

registerLocale('ru', ru);
setDefaultLocale('ru');

const reqDateFilterItems = [
  {
    value: 'yesterday',
    label: 'Вчера',
    dayStart: 1,
    dayEnd: 2,
    withoutDate: false,
  },
  {
    value: 'today',
    label: 'Сегодня',
    dayStart: 0,
    dayEnd: 1,
    withoutDate: false,
  },

  {
    value: 'week_ago',
    label: 'Неделя',
    dayStart: 0,
    dayEnd: 7,
    withoutDate: false,
  },
  {
    value: 'month_ago',
    label: 'Месяц',
    dayStart: 0,
    dayEnd: 30,
    withoutDate: false,
  },
  {
    value: 'year_ago',
    label: 'Год',
    dayStart: 0,
    dayEnd: 365,
    withoutDate: false,
  },
];

const STATUS_ICON_MAP: any = {
  Новая: 'red',
  Завершена: 'green',
  'В работе': 'blue',
  'Водитель назначен': 'pink',
};

const REQS_STATUS_MAP: any = {
  Новая: 'new',
  Готов: 'ready',
  Завершена: 'completed',
  'В работе': 'in-work',
  'Водитель назначен': 'appointed',
};

const CONTAINERS_STATUS_MAP: any = {
  'На базе': 'on-base',
  'У клиента': 'at-client',
};

const VEHICLES_STATUS_MAP: any = {
  'В ремонте': 'orange',
  'В работе': 'gray',
  Свободен: 'green',
};

type ActiveReqBlockList = 'new' | 'in_work' | 'completed';
type ActiveContainersBlockList = 'at_base' | 'at_clients';

type AllReqsStatuses = {
  id: ActiveReqBlockList;
  value: string;
  label: string;
};

export type AllContainersStatuses = {
  id: ActiveContainersBlockList;
  value: string;
  label: string;
};
const allReqsStatuses: AllReqsStatuses[] = [
  {
    id: 'new',
    value: 'Новая',
    label: 'Новые',
  },
  {
    id: 'in_work',
    value: 'В работе',
    label: 'В работе',
  },
  {
    id: 'completed',
    value: 'Завершена',
    label: 'Выполнены',
  },
];

const allContainersStatuses: AllContainersStatuses[] = [
  {
    id: 'at_base',
    value: 'На базе',
    label: 'На базе',
  },
  {
    id: 'at_clients',
    value: 'У клиента',
    label: 'У клиента',
  },
];

type ActiveBlocks = {
  [key in ActiveReqBlockList | ActiveContainersBlockList]: boolean;
};

const initialValueActiveBlock: ActiveBlocks = {
  new: true,
  in_work: true,
  completed: true,
  at_base: true,
  at_clients: true,
};

export type ContainerFilter = {
  cub: string;
  status: string;
};
const initialValueContainerFilter: ContainerFilter = {
  cub: '',
  status: '',
};

export default function MapWithReq() {
  const baseReqs: Req[] = [];
  const [reqs, setReqs] = useState(baseReqs);
  const baseCs: Container[] = [];
  const [cs, setCs] = useState(baseCs);
  const navigate = useNavigate();
  const baseVs: Vehicle[] = [];
  const [vs, setVs] = useState(baseVs);
  const [activeBlocks, setActiveBlocks] = useState<ActiveBlocks>(initialValueActiveBlock);
  const [selectContainerFilter, setSelectContainerFilter] = useState<ContainerFilter>(
    initialValueContainerFilter
  );
  const location = useLocation();

  let dk = localStorage.getItem('reqMapDateKind');
  if (typeof dk === 'undefined') {
    dk = 'today';
  }

  const { me }: { me: any } = useOutletContext();

  const [dateKind, setDateKind] = useState(dk);
  const sd1 = localStorage.getItem('reqMapStartDate');
  const sdZero = new Date();
  if (sd1 !== null) {
    sdZero.setTime(parseInt(sd1!));
  } else {
    sdZero.setTime(sdZero.getTime() - 365 * 24 * 3600 * 1000);
  }

  const [reqsContainersToggle, setReqsContainersToggle] = useState(true);
  const ed1 = localStorage.getItem('reqMapEndDate');
  const edZero = new Date();
  if (ed1 !== null) {
    edZero.setTime(parseInt(ed1!));
  }
  const [startDate, setStartDate] = useState(sdZero);
  const [endDate, setEndDate] = useState(edZero);
  const [counts, setCounts]: any[] = useState({
    count: 0,
    new_count: 0,
    finished_count: 0,
    in_work_count: 0,
    driver_appointed_count: 0,
    paid_count: 0,
    not_paid_count: 0,
  });

  const [query, setQuery] = useState('');
  const currentStatus = '';
  const paymentStatus = '';

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  const handleClickActiveBlockList = (id: ActiveReqBlockList | ActiveContainersBlockList): void => {
    setActiveBlocks((prevValue) => ({ ...prevValue, [id]: !prevValue[id] }));
  };

  const handleChangeFilterCubContainer = (event: SelectChangeEvent) => {
    setSelectContainerFilter((prev) => ({ ...prev, cub: event.target.value }));
  };
  const handleChangeFilterStatusContainer = (event: SelectChangeEvent) => {
    setSelectContainerFilter((prev) => ({
      ...prev,
      status: event.target.value,
    }));
  };

  useEffect(() => {
    getReqResult({
      navigate,
      startDate,
      endDate,
      query,
      currentStatus,
      paymentStatus,
    }).then(
      ({
        results,
        count,
        new_count,
        driver_appointed_count,
        in_work_count,
        finished_count,
        not_paid_count,
        paid_count,
      }: any) => {
        setReqs(results);
        setCounts({
          in_work_count,
          new_count,
          count,
          finished_count,
          not_paid_count,
          paid_count,
          driver_appointed_count,
        });
      }
    );
    async function fetchContainersData() {
      const res = await myFetch(`${SETTINGS.SITE_URL}/api/v1/containers/`, {}, navigate);
      setCs((await res.json()).results);
      const res2 = await myFetch(`${SETTINGS.SITE_URL}/api/v1/vehicles/`, {}, navigate);
      setVs((await res2.json()).results);
    }
    fetchContainersData();
  }, [startDate, endDate, query, currentStatus, paymentStatus, navigate]);

  useEffect(() => {
    if (location.state) {
      setReqsContainersToggle(location.state.key === 'req');
    }
  }, [location.state]);

  //////////////////////////////// УПРАВЛЕНИЕ WIALON
  const { vehicleLocations, getVehicleLocations, error: errorWialon } = useWialonVehiclesLocation();
  const [snackbarOpened, snackbarActions] = useDisclosure(false);

  useEffect(() => {
    const controller = new AbortController();
    const fetchVehicleLocations = () => {
      getVehicleLocations(controller.signal);
    };
    fetchVehicleLocations();

    const intervalId = setInterval(fetchVehicleLocations, 20000);

    return () => {
      controller.abort();
      clearInterval(intervalId);
    };
  }, [getVehicleLocations]);

  useEffect(() => {
    if (errorWialon !== null && vehicleLocations === null) {
      snackbarActions.open();
    }
  }, [errorWialon, vehicleLocations, snackbarActions.open]);
  // /////////////////////////////////////////////////////

  const cs_map = useMemo(() => {
    return getFilteredMapContainers(cs, selectContainerFilter);
  }, [cs, selectContainerFilter]);

  const formatDate = getDateOrder(startDate, endDate);

  return (
    <>
      <Snackbar
        open={snackbarOpened}
        autoHideDuration={SNACKBAR_CONFIG.autoHideDuration}
        onClose={snackbarActions.close}
        anchorOrigin={SNACKBAR_CONFIG.anchorOrigin}
      >
        <Alert variant="filled" severity="error">
          {errorWialon}
        </Alert>
      </Snackbar>
      <div className="application-map">
        <div className="page-top d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <span className="page-top__title">Карта заявок</span>
          </div>
          <div className="page-top__right d-flex items-center">
            <div className="page-top__calendar d-flex items-center">
              <div className="page-top__calendar-icon circle-center">
                <span className="icon-calendar"></span>
              </div>
              <div className="data-picker">
                <div
                  className={
                    formatDate === 'dd MMM' ? 'data-picker_start' : 'data-picker_start-axp'
                  }
                >
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      // setDateKind('by_date')
                      setStartDate(date!);
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat={formatDate}
                    shouldCloseOnSelect

                    // showYearPicker
                  />
                </div>
                -
                <div className="data-picker_end">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date!);
                      // setDateKind('by_date')
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd MMM yyyy"
                    shouldCloseOnSelect
                    style={{ width: '55px' }}
                    // showYearPicker
                  />
                </div>
              </div>
            </div>

            <div className="page-top__search">
              <TextField
                size="small"
                variant="outlined"
                value={query}
                onChange={handleChangeQuery}
                placeholder="Поиск заявки, клиента..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="icon-search"></span>
                    </InputAdornment>
                  ),
                  // endAdornment: (
                  //     <InputAdornment
                  //         position="end"
                  //         // style={{ display: showClearIconQuery }}
                  //         // onClick={handleClickClearQuery}
                  //     >
                  //         <span className="icon-close"></span>
                  //     </InputAdornment>
                  // )
                }}
              />
            </div>

            <div className="page-top__dispatcher d-flex items-center">
              <div className="page-top__dispatcher-icon circle-center">
                <span className="icon-profile"></span>
              </div>

              <span className="page-top__dispatcher-text d-flex items-center">
                Диспетчер · <span className="transition">{me?.full_name}</span>{' '}
                <span className="icon-angle-down transition"></span>
              </span>

              <div className="dispatcher-menu">
                <div className="dispatcher-menu__inner">
                  <a href="" className="dispatcher-menu__item transition">
                    Личные данные
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Сменить роль
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Выйти
                  </a>
                </div>
              </div>
            </div>

            <div className="page-top__setting d-flex items-center">
              <div className="page-top__setting-icon circle-center">
                <span className="icon-settings-b"></span>
              </div>

              <span className="page-top__setting-text d-flex items-center">
                <span className="transition">Настройки</span>
                <span className="icon-angle-down transition"></span>
              </span>

              <div className="setting-menu">
                <div className="setting-menu__inner">
                  <a href="" className="setting-menu__item transition">
                    Личные данные
                  </a>
                  <a href="" className="setting-menu__item transition">
                    Сменить роль
                  </a>
                  <a href="" className="setting-menu__item transition">
                    Выйти
                  </a>
                </div>
              </div>
            </div>

            <NavLink to={'/reqs/add/'} className="page-top__button d-flex items-center transition">
              Новая заявка
            </NavLink>
          </div>
        </div>

        <div className="page-content d-flex">
          <div className="application-map__tabs">
            <div className="tabs-top d-flex">
              <div
                onClick={() => {
                  setReqsContainersToggle(true);
                }}
                className={`tabs-top__item ${
                  reqsContainersToggle ? 'is-active' : ''
                } d-flex block-center transition`}
              >
                Все заявки
              </div>
              <div
                onClick={() => {
                  setReqsContainersToggle(false);
                }}
                className={`tabs-top__item ${
                  !reqsContainersToggle ? 'is-active' : ''
                } d-flex block-center transition`}
              >
                Контейнеры
              </div>
            </div>

            <div className="tabs-content">
              <div className="tabs-content__applications">
                {reqsContainersToggle &&
                  allReqsStatuses.map((s) => (
                    <div className="tabs-content__block" key={s.id}>
                      <div
                        className={`tabs-content__block-title ${
                          activeBlocks[s.id] ? 'is-active' : null
                        } d-flex justify-between items-center`}
                      >
                        {s.label}
                        <span
                          className="icon-angle-down transition"
                          onClick={() => handleClickActiveBlockList(s.id)}
                        ></span>
                      </div>
                      <div className="block-list">
                        {reqs?.map((r) => {
                          const formatedDate = getDateFormat(r.req_date, {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric',
                          });
                          return (
                            r.status === s.value && (
                              <div
                                className={`list-item ${REQS_STATUS_MAP[r.status]} d-flex`}
                                key={r.id}
                              >
                                {/* classes for statuses: new - Новые, in-work - В работе, completed - Выполнены */}
                                <div className="list-item__info d-flex flex-column">
                                  <Link to={`/reqs/${r.id}/edit`}>
                                    <span className="list-item__info-number">{`#${r.id}`}</span>{' '}
                                  </Link>
                                  <Link to={`/reqs/${r.id}/edit`}>
                                    <span className="list-item__info-title">{`${r.client?.name}`}</span>
                                  </Link>
                                </div>

                                <div className="list-item__date d-flex flex-column items-end">
                                  <span className="list-item__date-date">{formatedDate}</span>
                                  <span className="list-item__date-action icon-list transition"></span>
                                </div>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  ))}
                {!reqsContainersToggle &&
                  allContainersStatuses.map((s) => (
                    <div className="tabs-content__block" key={s.id}>
                      <div
                        className={`tabs-content__block-title ${
                          activeBlocks[s.id] ? 'is-active' : null
                        } d-flex justify-between items-center`}
                      >
                        {s.label}
                        <span
                          className="icon-angle-down transition"
                          onClick={() => handleClickActiveBlockList(s.id)}
                        ></span>
                      </div>
                      <div className="block-list">
                        {cs.map((c) => {
                          return (
                            c.status === s.value && (
                              <div
                                className={`list-item ${CONTAINERS_STATUS_MAP[c.status]} d-flex`}
                                key={c.id}
                              >
                                <div className="list-item__info d-flex flex-column">
                                  <Link to={`/containers/${c.id}/edit`}>
                                    <span className="list-item__info-number">{`#${c.id}`}</span>
                                    {' - '}
                                    <span className="list-item__info-kind">{c.kind} кубов</span>
                                  </Link>
                                  <Link to={`/containers/${c.id}/edit`}>
                                    <span className="list-item__info-title">{`#${c.num}`}</span>
                                    <span className="list-item__info-client">{`${c.current_client ?? ''}`}</span>
                                  </Link>
                                </div>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="application-map__map d-flex">
            <YMaps>
              <Map defaultState={{ center: [55.751574, 49.103856], zoom: 10 }}>
                {reqsContainersToggle ? (
                  <>
                    {reqs?.map((r) => {
                      if (r.address) {
                        return (
                          <Placemark
                            geometry={[r.address.coordinates!.lat, r.address.coordinates!.lng]}
                            properties={{
                              balloonContent: BallonContent(r),
                            }}
                            options={{
                              iconLayout: 'default#image',
                              iconImageHref: `/assets/map-icons/${
                                STATUS_ICON_MAP[r.status] || 'red'
                              }.png`,
                              iconImageSize: location.state
                                ? location.state.data.req_id === r.id
                                  ? [25, 25]
                                  : [15, 15]
                                : [15, 15],
                              iconImageOffset: [0, 0],
                            }}
                            modules={['geoObject.addon.balloon']}
                            key={r.id}
                          />
                        );
                      }
                    })}
                    (
                    {vehicleLocations?.map((auto: WialonVehicleLocations) => {
                      if (auto.item) {
                        return (
                          <Placemark
                            geometry={[auto.item.pos.y, auto.item.pos.x]}
                            properties={{
                              balloonContent: `${auto.item.nm}`,
                            }}
                            options={{
                              iconLayout: 'default#image',
                              iconImageHref: `/assets/map-icons/${
                                STATUS_ICON_MAP[auto.flags] || 'kamaz'
                              }.png`,
                              iconImageSize: [55, 45],
                              iconImageOffset: [0, 0],
                            }}
                            modules={['geoObject.addon.balloon']}
                            key={auto.item.id}
                          />
                        );
                      }
                    })}
                    )
                  </>
                ) : (
                  cs_map.map((c) => {
                    if (c.coordinates) {
                      return (
                        <Placemark
                          geometry={[c.coordinates.lat, c.coordinates.lng]}
                          properties={{
                            balloonContent: BallonContentContainer(c),
                          }}
                          options={{
                            iconLayout: 'default#image',
                            iconImageHref: `/assets/map-icons/${
                              STATUS_ICON_MAP[c.status] || 'red'
                            }.png`,
                            iconImageSize: location.state
                              ? location.state.data.container_id === c.id
                                ? [25, 25]
                                : [15, 15]
                              : [15, 15],
                            iconImageOffset: [0, 0],
                          }}
                          modules={['geoObject.addon.balloon']}
                          key={c.id}
                        />
                      );
                    }
                  })
                )}
              </Map>
            </YMaps>
            {!reqsContainersToggle && (
              <div className="application-map__select">
                <Select
                  value={selectContainerFilter.cub}
                  itemList={cubeType}
                  onChange={handleChangeFilterCubContainer}
                  label="Кубов"
                />
                <Select
                  value={selectContainerFilter.status}
                  itemList={allContainersStatuses.map((status) => status.label)}
                  onChange={handleChangeFilterStatusContainer}
                  label="Статус"
                />
              </div>
            )}
          </div>

          <div className="application-map__aside">
            <div className="aside-details">
              <div className="aside-details__filter d-flex">
                {reqDateFilterItems.map(
                  (item: {
                    value: string;
                    label: string;
                    dayStart: number;
                    dayEnd: number;
                    withoutDate: boolean;
                  }) => {
                    const classList = [
                      'aside-details__filter-item',
                      'd-flex items-center',
                      'transition',
                      dateKind === item.value ? 'is-active' : '',
                    ].join(' ');
                    return (
                      <div
                        key={item.value}
                        className={classList}
                        onClick={() => {
                          setDateKind(item.value);
                          localStorage.setItem('reqMapDateKind', item.value);
                          if (item.withoutDate) {
                            localStorage.setItem('reqMapStartDate', '0');
                            localStorage.setItem('reqMapEndDate', '0');
                            setStartDate(new Date(0));
                            setEndDate(new Date(0));
                          } else {
                            const sd = new Date(
                              new Date().getTime() - item.dayEnd * 24 * 60 * 60 * 1000
                            );
                            localStorage.setItem('reqMapStartDate', sd.getTime().toString());
                            const ed = new Date(
                              new Date().getTime() - item.dayStart * 24 * 60 * 60 * 1000
                            );
                            localStorage.setItem('reqMapEndDate', ed.getTime().toString());
                            setStartDate(sd);
                            setEndDate(ed);
                          }
                        }}
                      >
                        {item.label}
                      </div>
                    );
                  }
                )}
              </div>

              <div className="details-list d-flex flex-wrap justify-between">
                <div className="details-list__item d-flex flex-column">
                  <img
                    src="/assets/images/total-applications.svg"
                    className="details-list__item-icon"
                    alt="total applications icon"
                  />
                  <span className="details-list__item-title">Всего заявок</span>
                  <span className="details-list__item-value">{counts.count}</span>
                </div>
                <div className="details-list__item d-flex flex-column">
                  <img
                    src="/assets/images/new-applications.svg"
                    className="details-list__item-icon"
                    alt="new applications icon"
                  />
                  <span className="details-list__item-title">Новых заявок</span>
                  <span className="details-list__item-value">{counts.new_count}</span>
                </div>
                <div className="details-list__item d-flex flex-column">
                  <img
                    src="/assets/images/in-work-applications.svg"
                    className="details-list__item-icon"
                    alt="in work applications icon"
                  />
                  <span className="details-list__item-title">Заявок в работе</span>
                  <span className="details-list__item-value">{counts.in_work_count}</span>
                </div>
                <div className="details-list__item d-flex flex-column">
                  <img
                    src="/assets/images/completed-applications.svg"
                    className="details-list__item-icon"
                    alt="completed applications icon"
                  />
                  <span className="details-list__item-title">Выполненных</span>
                  <span className="details-list__item-value">{counts.finished_count}</span>
                </div>
              </div>
            </div>

            <div className="aside-cars">
              <span className="aside-cars__title">Автомашины</span>
              {vs.map((v: Vehicle) => {
                const finishedReqs = v.reqs.filter((req) => req.status === 'Завершена');
                return (
                  <div className="cars-list" key={v.id}>
                    <div className={`list-item ${VEHICLES_STATUS_MAP[v.status]} d-flex`}>
                      <div className="list-item__info d-flex flex-column">
                        <span className="list-item__info-title">
                          {v.brand?.name || 'Неизв'} · {v.max_volume} кубов
                        </span>
                        <Link to={`/vehicles/${v.id}/edit/`}>
                          <span className="list-item__info-number">{v.license_plate}</span>
                        </Link>
                      </div>

                      <div className="list-item__route d-flex flex-column items-end">
                        <span className="list-item__route-text">{v.status}</span>
                        <div className="list-item__route-info d-flex ">
                          {finishedReqs.length} из {v.reqs.length}{' '}
                          <span className="icon-cube-black"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
