import { useContext } from 'react';
import { FormContext } from '../../context/formContext/ReqAddContext';

export const useAddFormContext = () => {
  const context = useContext(FormContext);

  if (context === null) {
    throw new Error('useAddFormContext must be used within a FormProvider');
  }
  return context;
};
