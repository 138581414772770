import { Schedules } from '@/interfaces/driver';
import SETTINGS from '@/settings';
import { Group } from '@/ui';
import { myFetch } from '@/utils';
import { isDateInThePast } from '@/utils/isDateInThePast';
import {
  Box,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from '@mui/material';
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const DateCalendarMui = styled(DateCalendar)(() => ({
  width: '256px',
  height: 'auto',
  margin: 0,
  '& .MuiPickersCalendarHeader-root': {
    paddingLeft: '10px',
  },
  '& .MuiDayCalendar-root': {
    '& .MuiPickersSlideTransition-root': {
      minHeight: '188px',
      '& .MuiPickersDay-root[aria-current="date"]': {
        backgroundColor: 'black',
        color: 'white',
      },
      '& .MuiPickersDay-root[disabled]': {
        opacity: '0.4',
      },
      '& .MuiDayCalendar-monthContainer': {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',

        '& .MuiDayCalendar-weekContainer': {
          gap: '2px',
        },
      },
    },
    '& .MuiDayCalendar-header': {
      gap: '2px',
      '& .MuiDayCalendar-weekDayLabel': {
        width: '28px',
        height: '28px',
      },
    },
    '& .MuiPickersDay-root': {
      width: '28px',
      height: '28px',
    },
  },
}));

type SpecialDates = {
  ready: number[];
  weekend: number[];
  sick: number[];
  timeOff: number[];
  ids: { [key: string]: number };
};

function ServerDay(
  props: PickersDayProps<Dayjs> & {
    specialDates?: SpecialDates;
    onDayClick?: (
      date: Dayjs,
      event: React.MouseEvent<HTMLButtonElement>,
      id: number | undefined
    ) => void;
  }
) {
  const { specialDates, onDayClick, day, outsideCurrentMonth, ...other } = props;

  const isSelectedReady = specialDates?.ready.some(
    (specialDate) => specialDate === day.toDate().getTime()
  );

  const isSelectedWeekend = specialDates?.weekend.some(
    (specialDate) => specialDate === day.toDate().getTime()
  );

  const isSelectedSick = specialDates?.sick.some(
    (specialDate) => specialDate === day.toDate().getTime()
  );

  const isSelectedTimeOff = specialDates?.timeOff.some(
    (specialDate) => specialDate === day.toDate().getTime()
  );

  const isPassedDay = isDateInThePast(day.toDate());

  return (
    <PickersDay
      {...other}
      day={day}
      disabled={isPassedDay}
      selected={false}
      outsideCurrentMonth={outsideCurrentMonth}
      onClick={(event) =>
        onDayClick && onDayClick(day, event, specialDates?.ids[String(day.toDate().getTime())])
      }
      sx={{
        width: '28px',
        height: '28px',
        fontSize: '12px',
        borderRadius: '8px',
        ...(isSelectedReady && { backgroundColor: '#AAFFDB', color: 'black' }),
        ...(isSelectedWeekend && {
          color: '#B2B3C3',
          border: '1px solid #B2B3C3',
        }),
        ...(isSelectedSick && {
          backgroundColor: 'red',
          color: '#fff',
        }),
        ...(isSelectedTimeOff && {
          backgroundColor: '#a89999',
          color: '#fff',
          border: '1px solid #000',
        }),
      }}
    />
  );
}

export const DateCalendare = () => {
  const navigate = useNavigate();
  const { driverId } = useParams();
  const [highlightedDays, setHighlightedDays] = useState<SpecialDates | null>(null);
  const [showSelect, setShowSelect] = useState(false);
  const [selectPosition, setSelectPosition] = useState<{ top: number; left: number } | null>(null);
  const [selectDate, setSelectDate] = useState<{ date: Date; id: number } | null>(null);
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const [refetch, setRefetch] = useState(true);

  const getStatusDateObject = useCallback((data: any): SpecialDates => {
    const result: SpecialDates = {
      ready: [],
      weekend: [],
      sick: [],
      timeOff: [],
      ids: {},
    };

    data.results[0].schedules.forEach((sch: Schedules) => {
      const date = new Date(sch.date_schedule).setHours(0, 0, 0, 0);
      result.ids[new Date(sch.date_schedule).setHours(0, 0, 0, 0)] = sch.id;

      if (sch.status === 'Готов') {
        result.ready.push(date);
      } else if (sch.status === 'Выходной') {
        result.weekend.push(date);
      } else if (sch.status === 'Больничный') {
        result.sick.push(date);
      } else if (sch.status === 'Отгул') {
        result.timeOff.push(date);
      }
    });

    return result;
  }, []);

  useEffect(() => {
    if (!refetch) return;
    const fetchDriverShedule = async () => {
      try {
        const response = await myFetch(
          `${SETTINGS.SITE_URL}/api/v1/drivers/with-schedules/?driver=${driverId}&current_month=true`,
          {},
          navigate
        );
        if (!response.ok) throw new Error('Проблема с получением данных по графике');

        const data = await response.json();
        const calendar = getStatusDateObject(data);

        setHighlightedDays(calendar);
        setRefetch(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchDriverShedule();
  }, [navigate, driverId, getStatusDateObject, refetch]);

  const handleChangeDatePicker = async (date: Dayjs) => {
    const selectedDate = { year: date.year(), month: date.month() };
    const response = await myFetch(
      `${SETTINGS.SITE_URL}/api/v1/drivers/with-schedules/?driver=${driverId}&year=${selectedDate.year}&month=${selectedDate.month + 1}`,
      {},
      navigate
    );
    if (!response.ok) throw new Error('Проблема с получением данных по графике');

    const data = await response.json();
    const calendar = getStatusDateObject(data);

    setHighlightedDays(calendar);
  };

  const handleDayClick = (date: Dayjs, event: React.MouseEvent<HTMLButtonElement>, id: number) => {
    const button = event.currentTarget;
    const rect = button.getBoundingClientRect();
    const calendarRect = calendarRef.current?.getBoundingClientRect();
    if (calendarRect) {
      setSelectPosition({
        top: rect.top - calendarRect.top + rect.height,
        left: rect.left - calendarRect.left,
      });
    }
    setSelectDate({ date: date.toDate(), id });
    setShowSelect(true);
  };

  const handleChangeSelect = async (event: SelectChangeEvent) => {
    const formattedDAte = selectDate?.date
      .toLocaleString('ru-RU', { dateStyle: 'short' })
      .split('.')
      .reverse()
      .join('-');
    setShowSelect(false);

    await myFetch(
      `${SETTINGS.SITE_URL}/api/v1/drivers/schedules/${selectDate?.id}/`,
      {
        method: 'PUT',
        body: JSON.stringify({
          id: selectDate?.id,
          status: event.target.value,
          date_schedule: formattedDAte,
        }),
      },
      navigate
    );
    setRefetch(true);
  };

  return (
    <Group gap={7}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <div ref={calendarRef} style={{ position: 'relative' }}>
          <DateCalendarMui
            // disablePast
            onMonthChange={handleChangeDatePicker}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                specialDates: highlightedDays,
                onDayClick: handleDayClick,
              } as any,
            }}
          />
          {showSelect && selectPosition && (
            <Select
              defaultOpen
              style={{
                position: 'absolute',
                top: `${selectPosition.top}px`,
                left: `${selectPosition.left}px`,
                visibility: 'hidden',
                height: '8px',
                width: '28px',
              }}
              onChange={handleChangeSelect}
              onClick={() => setShowSelect(false)}
            >
              <MenuItem value="Готов">Г</MenuItem>
              <MenuItem value="Больничный">Б</MenuItem>
              <MenuItem value="Отгул">О</MenuItem>
              <MenuItem value="Выходной">В</MenuItem>
            </Select>
          )}
        </div>
      </LocalizationProvider>
      <Box display="flex" flexDirection="column" justifyContent="end" pb={1.8}>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ height: '158px' }} />
      </Box>
      <Box display="flex" flexDirection="column" gap={1.2} paddingTop={7.8}>
        <Typography variant="h6" fontSize={10} sx={{ opacity: '0.6' }}>
          Часы:
        </Typography>
        <Typography fontSize={14} fontWeight={600}>
          38 ч
        </Typography>
        <Typography fontSize={14} fontWeight={600}>
          27 ч
        </Typography>
        <Typography fontSize={14} fontWeight={600}>
          41 ч
        </Typography>
        <Typography fontSize={14} fontWeight={600}>
          12 ч
        </Typography>
        <Typography fontSize={14} fontWeight={600}>
          0 ч
        </Typography>
      </Box>
    </Group>
  );
};
