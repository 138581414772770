import { Dialog, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useRef, useEffect, ReactNode, FC, PropsWithChildren } from 'react';
import { Group } from '../ui/mui/Group/Group';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <div className="modal-close icon-close transition" onClick={onClose}></div>
        {children}
      </div>
    </div>
  );
};
export default Modal;
// const App: React.FC = () => {
//   const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

//   const openModal = (): void => setIsModalOpen(true);
//   const closeModal = (): void => setIsModalOpen(false);

//   return (
//     <div>
//       <button onClick={openModal}>Открыть модальное окно</button>
//       <Modal isOpen={isModalOpen} onClose={closeModal}>
//         <h2>Заголовок модального окна</h2>
//         <p>Содержимое модального окна</p>
//       </Modal>
//     </div>
//   );
// };

// export default App;

export interface SimpleDialogProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  w?: number;
}

export const ModalMui: FC<SimpleDialogProps & PropsWithChildren> = ({ children, ...props }) => {
  const { onClose, opened, title, w } = props;

  return (
    <Dialog
      onClose={onClose}
      open={opened}
      maxWidth={'xl'}
      sx={{ '.MuiDialog-paper': { borderRadius: '20px', width: `${w}px` } }}
    >
      <Group gap={10}>
        <DialogTitle fontSize={20} sx={{ textAlign: 'center', width: 'fit-content' }}>
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
      </Group>
      {children}
    </Dialog>
  );
};
