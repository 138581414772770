export const getDateFormat = (
  date: Date | string,
  rest: Intl.DateTimeFormatOptions | undefined
): string => {
  const options = { ...rest };
  const newDate = new Date(date);
  const formatter = new Intl.DateTimeFormat('ru-RU', options);
  const formattedDate = formatter.format(newDate);

  return formattedDate;
};
