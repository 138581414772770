import { WEEK_DAYS } from '@/constants';
import { getDaysInMonth } from '@/utils/getDaysInMonth';
import { useMemo } from 'react';

type DaysAndWeekdaysInMonth = (date: Date) => { days: number[]; weekDaysInMonth: string[] };

export const useDaysAndWeekdaysInMonth: DaysAndWeekdaysInMonth = (date) => {
  const getDaysAndWeekdaysInMonth = useMemo(() => {
    const year = date.getFullYear();
    const month = date.getMonth();

    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfWeek = new Date(year, month, 1).getDay();

    const days = new Array(daysInMonth).fill(0).map((_, i) => i + 1);

    const weekDaysInMonth = days.map((_, i) => {
      let dayOfWeek = (firstDayOfWeek + i - 1) % 7;
      if (dayOfWeek < 0) dayOfWeek += 7;
      return WEEK_DAYS[dayOfWeek];
    });

    return { days, weekDaysInMonth };
  }, [date]);

  return getDaysAndWeekdaysInMonth;
};
