import { Badge, Stack, TextField, styled } from '@mui/material';
import { FC } from 'react';

type InputOutLabel = React.ComponentProps<typeof TextField> & {
  label: string;
  w?: number;
  bg?: string;
  developed?: boolean;
};
const InputField = styled(TextField)<Omit<InputOutLabel, 'label'>>(({ w, bg, disabled }) => ({
  height: 'var(--input-height)',
  width: `${w}px`,

  '.MuiInputBase-root': {
    height: '100%',
    borderRadius: '10px',
    backgroundColor: bg ?? '#F6F6F9',
    boxShadow: disabled ? 'inset 1px 1px 2px 0px #c9c9c9' : '4px 3px 2px #F6F6F9',

    fieldset: {
      borderWidth: disabled ? '0px' : '1px',
      borderColor: 'var(--input-border-color)',
    },
  },
}));
const Label = styled('label')(() => ({
  fontSize: '12px',
  fontWeight: 400,
}));

export const InputOutLabal: FC<InputOutLabel> = ({ w, bg, label, developed = false, ...props }) => {
  return (
    <Stack gap={1.2} sx={{ width: `${w}px` }}>
      <Label>{label}</Label>
      <Badge badgeContent={'в работе'} color="warning" invisible={!developed}>
        <InputField w={w} bg={bg} {...props} />
      </Badge>
    </Stack>
  );
};
