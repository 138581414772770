import { Stack } from '@mui/material';
import { Group } from '../../../../ui/mui/Group/Group';
import { ViewingField } from '../../../../ui/Fields/ViewingField';
import { SelectForm } from '../../../../ui/mui/Select/Select';
import { routeConfig } from '../../../../params/req';
import { FC, useCallback, useState } from 'react';
import { useContainerSelect } from '@/hooks/Reqs/useContainerSelect';
import { Container, ReqForm } from '@/interfaces';
import { useObjectContainerSelect } from '@/hooks/Reqs/useObjectContainerSelect';

type RouteStateProps = {
  routeState: string;
  address_location: string;
  isReqFinished: boolean;
  onChange: (field: keyof ReqForm) => (value: any) => void;
  containerState: { [key in 'take_container' | 'container']: Container | undefined };
  objectContainers: Container[] | undefined;
};

const CHECKING_FIELD = 'Адрес загрузки контейнера';

export const RouteState: FC<RouteStateProps> = ({
  routeState,
  address_location,
  isReqFinished,
  containerState,
  objectContainers,
  onChange,
}) => {
  const { container_numbers, containers } = useContainerSelect();
  const [addressLoading, setAddressLoading] = useState(
    containerState.take_container?.last_active_req_location
  );
  const { objectContainerList } = useObjectContainerSelect(objectContainers ?? []);

  const config = routeConfig[routeState];

  const handleSelectContainer = useCallback(
    (id: string) => {
      if (!id) return;

      const selectedContainer = containers?.find((container) => container.id === +id);
      if (selectedContainer) {
        const lastAddress =
          selectedContainer.last_active_req_location || selectedContainer.location || '';
        setAddressLoading(lastAddress);
      }

      onChange('container')(+id);
    },
    [containers, onChange]
  );

  const handleSelectTakeContainer = useCallback(
    (id: string) => {
      onChange('take_container')(+id);
    },
    [onChange]
  );

  if (!config) return null;

  return (
    <Stack gap={1.4}>
      {config.map((item, index) => (
        <Group gap={10} key={index}>
          <ViewingField
            label={item.label}
            value={item.label === CHECKING_FIELD ? addressLoading : address_location}
            w={79}
            disabled={item.disabled}
          />
          {item.hasContainer && (
            <SelectForm
              name={item.label === CHECKING_FIELD ? 'take' : 'put'}
              list={
                item.label === CHECKING_FIELD || routeState === 'Поставил'
                  ? container_numbers
                  : objectContainerList
              }
              label="Выберите контейнер"
              w={190}
              disabled={isReqFinished}
              defaultValue={
                (item.label === CHECKING_FIELD
                  ? containerState.container?.id
                  : containerState.take_container?.id) || containerState.container?.id
              }
              onChange={
                item.label === CHECKING_FIELD ||
                routeState === 'Поставил' ||
                routeState === 'Забрал'
                  ? (event) => handleSelectContainer(event.target.value)
                  : (event) => handleSelectTakeContainer(event.target.value)
              }
            />
          )}
        </Group>
      ))}
    </Stack>
  );
};
