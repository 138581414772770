import { Stack } from '@mui/material';
import { AddForm } from '../../components/req/form/add-form/Addform';
import { FormProvider } from '../../context/formContext/ReqAddContext';

export default function ReqAdd() {
  return (
    <Stack sx={{ backgroundColor: '#fff', padding: '20px', borderRadius: '10px' }}>
      <FormProvider>
        <AddForm />
      </FormProvider>
    </Stack>
  );
}
