import { NavigateFunction } from 'react-router-dom';
import SETTINGS from '../../settings';
import { myFetch } from '../../utils';

export const fetchWialonVehicleLocations = async (
  navigate: NavigateFunction,
  signal?: AbortSignal
): Promise<Response> => {
  return await myFetch(
    `${SETTINGS.SITE_URL}/api/v1/vehicles/current-locations/`,
    { signal },
    navigate
  );
};
