import { FC } from 'react';
import { Group } from '../../../../ui/mui/Group/Group';
import { BadgeRouteStatus } from '../../../../ui/Badges/RouteStatus';

type RouteList = {
  id: number;
  label: string;
};
const routesList: RouteList[] = [
  { id: 1, label: 'Поставил - забрал' },
  { id: 2, label: 'Забрал' },
  { id: 3, label: 'Поставил' },
];

type RoutesProps = {
  kind: string;
  onClick: (value: string) => void;
};
export const Routes: FC<RoutesProps> = ({ kind, onClick }) => {
  return (
    <Group gap={2}>
      {routesList.map((route) => (
        <BadgeRouteStatus
          key={route.id}
          isActive={kind === route.label}
          onClick={() => onClick(route.label)}
        >
          {route.label}
        </BadgeRouteStatus>
      ))}
    </Group>
  );
};
