import { styled } from '@mui/material';
import { ViewingField } from '../../../ui/Fields/ViewingField';
import { AdditionalServiceState, Additional_services } from '../../../interfaces/req';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectForm } from '../../../ui/mui/Select/Select';

const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '20px',
  backgroundColor: '#F6F6F9',
  height: '64px',
  padding: '0 10px',
}));
const ButtonBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#EEEEF3',
  borderRadius: '10px',
  height: '44px',
  width: '30px',
  cursor: 'pointer',
}));

type ExtraService = {
  serviceData: Additional_services;
  onRemove: (id: number) => void;
  selecData: { serviceCost: { [key: number]: number }; serviceList: never[] };
  callback: (service: AdditionalServiceState) => void;
  disabled?: boolean;
};

export const ExtraService: FC<ExtraService> = ({
  serviceData,
  onRemove,
  selecData,
  disabled,
  callback,
}) => {
  const { id, service } = serviceData;

  const [selectValue, setSelectValue] = useState(service?.id ?? 0);

  useEffect(() => {
    setSelectValue(service?.id ?? 0);
  }, [service]);

  const handleDelete = (id: number) => {
    onRemove(id);

    const additionalServiceDelete = {
      id,
      to_delete: true,
    };
    callback(additionalServiceDelete);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const idService = event.target.value;
    setSelectValue(+idService);

    if (!id) {
      const additionalServiceAdd = {
        service: {
          id: +idService,
        },
      };

      callback(additionalServiceAdd);
    } else {
      const additionalServiceEdit = {
        id,
        service: {
          id: +idService,
        },
      };
      callback(additionalServiceEdit);
    }
  };

  return (
    <Wrapper>
      <SelectForm
        label={'Наименование услуги'}
        defaultValue={service?.id ?? ''}
        w={1030}
        disabled={disabled}
        list={selecData.serviceList}
        bg="#fff"
        onChange={handleChange}
      />
      <ViewingField
        label={'Стоимость'}
        value={String(selecData.serviceCost[selectValue ?? ''] ?? '')}
        bg="#fff"
        w={20}
      />
      <ButtonBox onClick={() => (disabled ? {} : handleDelete(id!))}>
        <DeleteIcon sx={{ width: '16px' }} />
      </ButtonBox>
    </Wrapper>
  );
};
