import { Container } from '@/interfaces';
import { useMemo } from 'react';

type ObjectContainerSelect = (containers: Container[]) => {
  objectContainerList: { id: number; label: string }[];
};

export const useObjectContainerSelect: ObjectContainerSelect = (containers) => {
  const objectContainerList = useMemo(() => {
    return (
      containers?.map((container) => ({
        id: container.id,
        label: `#${container.num} (тип - ${container.kind})`,
      })) || []
    );
  }, [containers]);

  return { objectContainerList };
};
