import { Container } from '../interfaces/container';
import { ContainerFilter } from '../pages/MapWithReqs';

type CubRangesType = {
  [key: string]: number[];
  '8': number[];
  '15': number[];
  '18': number[];
  '27': number[];
  '35': number[];
};
const cubRanges: CubRangesType = {
  '8': [0, 8],
  '15': [8, 15],
  '18': [15, 18],
  '27': [18, 27],
  '35': [27, 35],
};

type getFilteredMapContainers = (cs: Container[], filter: ContainerFilter) => Container[];

export const getFilteredMapContainers: getFilteredMapContainers = (cs, filter) => {
  console.log(filter);
  const filterFunction = (el: Container) => {
    const integerPart = Math.floor(el.volume);
    const { status, cub } = filter;

    const statusValue = status === '' || el.status === status;
    const cubRange = cub ? cubRanges[cub] : null;
    const cubeValue = cubRange ? integerPart >= cubRange[0] && integerPart <= cubRange[1] : true;

    return statusValue && cubeValue;
  };

  return cs.filter(filterFunction);
};
