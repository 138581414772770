import { Stack } from '@mui/material';
import { AddForm } from '../../components/container/form/AddForm';

export default function ContainerAdd() {
  return (
    <>
      <Stack
        sx={{
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '10px',
          height: 'calc(100vh - 20px)',
        }}
      >
        <AddForm />
      </Stack>
    </>
  );
}
