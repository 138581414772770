import { useCallback, useMemo, useState } from 'react';
import { ERROR_MESSAGES, VALIDATION_FIELDS } from '../../constants/constants';
import SETTINGS from '../../settings';
import { myFetch } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Req, ReqForm } from '../../interfaces/req';

interface ReqEditFormReturn {
  formData: Partial<ReqForm> | null;
  setFormData: React.Dispatch<React.SetStateAction<Partial<ReqForm> | null>>;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  snackbarMessage: string;
  setSnackBarMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const useReqEditForm = (initialReq: Req, onOpenModal: () => void): ReqEditFormReturn => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<Partial<ReqForm> | null>(null);
  const [snackbarMessage, setSnackBarMessage] = useState(ERROR_MESSAGES.DEFAULT);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (formData) {
        const res = await myFetch(
          `${SETTINGS.SITE_URL}/api/v1/reqs/${initialReq.id}/`,
          {
            method: 'PATCH',
            body: JSON.stringify(formData),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          },
          navigate
        );
        if (res.ok) {
          navigate('/reqs');
        } else {
          onOpenModal();
          const jsonData = await res.json();
          for (const msg of VALIDATION_FIELDS) {
            if (jsonData[msg]) {
              setSnackBarMessage(`${msg} - ${jsonData[msg]}`);
              break;
            }
          }
        }
      } else {
        setSnackBarMessage('Изменения не были внесены');
        onOpenModal();
      }
    },
    [formData, initialReq.id, navigate, onOpenModal]
  );

  return useMemo(
    () => ({
      formData,
      setFormData,
      handleSubmit,
      snackbarMessage,
      setSnackBarMessage,
    }),
    [formData, handleSubmit, snackbarMessage]
  );
};
