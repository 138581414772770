import { useEffect, useState } from 'react';
import SETTINGS from '../../settings';
import MyForm from '../../components/MyForm';
import { exportFileFatch, myFetch } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import vehicleEditFields from '../../form-fields/vehicle-edit';
import Modal, { ModalMui } from '../../components/Modal';
import { useDisclosure } from '../../hooks/useDisclosure';
import { SendRepair } from '../../components/vehicle/send-repair/SendRepair';
import { Box } from '@mui/material';
import { Select } from '../../components/select/Select';
import { downloadFile } from '../../utils/downloadFile';

export default function VehicleEdit() {
  // const reqId = props.match.params.id
  const { vehicleId } = useParams();
  const baseVehicle: any = { reqs: [] };
  const [vehicle, setVehicle] = useState(baseVehicle);
  const navigate = useNavigate();
  const baseErrors: any = {};
  const [errors, setErrors] = useState(baseErrors);
  const baseRepairs: any[] = [];
  const [repairs, setRepairs] = useState(baseRepairs);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await myFetch(`${SETTINGS.SITE_URL}/api/v1/vehicles/${vehicleId}/`, {}, navigate);
      const v = await res.json();
      setVehicle(v);
      const res2 = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/vehicles/${vehicleId}/repairs/`,
        {},
        navigate
      );
      const repJson = await res2.json();
      setRepairs(repJson.results);
    };
    fetchData();
  }, [vehicleId, navigate]);

  const handleExportFile = async () => {
    const params = {
      page: '',
    };

    const response = await exportFileFatch('vehicles', navigate, params, `${vehicleId}/repairs`);
    try {
      await downloadFile(response, `repair_history${vehicleId}`, 'xlsx');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="car-page d-flex flex-wrap">
        <div className="page-top page-top--black d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <div className="page-top__back circle-center" onClick={() => navigate(-1)}>
              <span className="icon-angle-left"></span>
            </div>
            <span className="page-top__title">
              {vehicle.brand?.name} · {vehicle.max_volume} кубов · {vehicle.license_plate}
            </span>
            <div className="page-top__status in-work d-flex items-center">{vehicle.status}</div>
            {/* Завершен - completed, В работе - in-work */}
          </div>

          <div className="page-top__right d-flex items-center">
            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-profile"></span>
              </div>
              Водитель <b>· {vehicle.driver?.full_name}</b>
            </div>

            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-phone"></span>
              </div>
              Телефон <b>· +7{vehicle.driver?.phone}</b>
            </div>

            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-check-circle"></span>
              </div>
              Заявок завершено <b>· {vehicle.reqs_finished}</b>
            </div>

            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-routing"></span>
              </div>
              Общий километраж <b>· 105 000 км</b>
            </div>
          </div>
        </div>

        <div className="car-page__top d-flex">
          <div className="car-page__top-item d-flex items-center transition">
            <span className="icon-map transition"></span>
            Посмотреть на карте
          </div>
          <div
            className="car-page__top-item car-page__top-item--delete d-flex items-center transition"
            onClick={() => {
              setDeleteModalIsOpen(true);
            }}
          >
            <span className="icon-delete transition"></span>
            Удалить авто
          </div>
          <Modal isOpen={deleteModalIsOpen} onClose={() => {}}>
            Вы уверены, что хотите удалить автомашину?
            <button
              onClick={() => {
                async function deleteVehicle() {
                  const res = await myFetch(
                    `${SETTINGS.SITE_URL}/api/v1/vehicles/${vehicleId}/`,
                    { method: 'DELETE' },
                    navigate
                  );
                  if (res.status == 204) {
                    navigate('/vehicles');
                  }
                }
                deleteVehicle();
              }}
            >
              Да, уверен
            </button>
            <button onClick={() => setDeleteModalIsOpen(false)}>Назад</button>
          </Modal>
          <div className="car-page__top-item d-flex items-center transition" onClick={open}>
            <span className="icon-settings transition"></span>
            Отправить в ремонт
          </div>
          <ModalMui opened={opened} onClose={close} title={'Отправить на ремонт'}>
            <SendRepair id={vehicleId!} closeModal={close} />
          </ModalMui>
        </div>

        <div className="car-page__content">
          <MyForm
            fields={vehicleEditFields}
            method="PUT"
            returnUrl={`/vehicles/`}
            formUrl={`${SETTINGS.SITE_URL}/api/v1/vehicles/${vehicleId}/`}
            state={vehicle}
            setState={setVehicle}
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <div className="car-page__history">
          <div className="car-page__history-tabs d-flex">
            <div className="car-page__history-tab is-active d-flex block-center transition">
              Эксплуатация авто
            </div>
            <div className="car-page__history-tab d-flex block-center transition">
              История заправок
            </div>
          </div>

          <div className="car-page__history-content d-flex flex-wrap">
            <div className="tab-content d-flex flex-wrap">
              <div className="history-list">
                {vehicle.reqs.map((r: any, i: number) => {
                  return (
                    <div className="history-item d-flex justify-between" key={i}>
                      <div className="history-item__column d-flex flex-column">
                        <span className="history-item__column-text">{r.req_date}</span>
                        <span className="history-item__column-title">Заявка #{r.id}</span>
                      </div>

                      <div className="history-item__column d-flex flex-column">
                        <span className="history-item__column-text">Водитель</span>
                        <span className="history-item__column-driver d-flex items-center">
                          {r.driver?.full_name}
                          <span className="icon-profile-black"></span>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>

              <a
                href=""
                className="car-page__history-download d-flex items-center transition"
                download
              >
                <span className="icon-document-text transition"></span>
                Скачать историю эксплуатации
              </a>
            </div>
          </div>
        </div>

        <div className="car-page__table">
          <div className="car-page__table-top d-flex justify-between items-center">
            <h2 className="form__title">История ремонтов</h2>
            <Box style={{ width: '101px' }}>
              <Select
                onChange={handleExportFile}
                itemList={['Экспорт в xlsx']}
                value={''}
                label={'Действия'}
              />
            </Box>
          </div>

          <table className="repairs-table table">
            <thead className="table__top">
              <tr className="table__top-tr">
                <th className="table__top-th th-sent">
                  <div className="d-flex items-center">
                    <label className="table__checkbox">
                      <input type="checkbox" className="table__checkbox-input" />
                      <div className="table__checkbox-block d-flex block-center transition">
                        <span className="icon-check"></span>
                      </div>
                    </label>
                    Отправлен
                  </div>
                </th>
                <th className="table__top-th th-status">Статус</th>
                <th className="table__top-th th-reason">Причина ремонта</th>
                <th className="table__top-th th-exit">Выход на линию</th>
                <th className="table__top-th th-responsible">Ответственный</th>
                <th className="table__top-th th-driver">Водитель</th>
              </tr>
            </thead>
            <tbody>
              {repairs.map((rep) => (
                <tr className="table__row" key={rep.id}>
                  <td className="table__td td-sent">
                    <div className="table__td-inner d-flex items-center">
                      <label className="table__checkbox">
                        <input type="checkbox" className="table__checkbox-input" />
                        <div className="table__checkbox-block d-flex block-center transition">
                          <span className="icon-check"></span>
                        </div>
                      </label>
                      {rep.created_at}
                    </div>
                  </td>
                  <td className="table__td td-status">
                    <div className="table__td-inner d-flex items-center">
                      <div className="td-status__block repair d-flex items-center">
                        {/* classes for statuses: repair - Ремонт, completed - Завершен */}
                        Ремонт
                      </div>
                    </div>
                  </td>
                  <td className="table__td td-reason">
                    <div className="table__td-inner d-flex items-center">{rep.reason.name}</div>
                  </td>
                  <td className="table__td td-exit">
                    <div className="table__td-inner d-flex items-center">{rep.repair_date}</div>
                  </td>
                  <td className="table__td td-responsible">
                    <div className="table__td-inner d-flex items-center">
                      {rep.created_by.full_name}
                    </div>
                  </td>
                  <td className="table__td td-driver">
                    <div className="table__td-inner d-flex items-center">
                      {rep.driver?.full_name}
                    </div>
                  </td>
                  <a href="" className="table__move circle-center transition">
                    <span className="icon-arrow-right transition"></span>
                  </a>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
