import { useState, useEffect } from 'react';
import SETTINGS from '../../settings';
import { myFetch } from '../../utils';
import { useNavigate, useOutletContext } from 'react-router-dom';

export default function LogUserActionList() {
  const logs: any[] = [];
  const [logItems, setLogItems] = useState(logs);
  const navigate = useNavigate();

  const [count, setCount] = useState(0);

  const { me }: { me: any } = useOutletContext();

  useEffect(() => {
    async function fetchData() {
      const res = await myFetch(`${SETTINGS.SITE_URL}/api/v1/log/`, {}, navigate);
      const _json = await res.json();
      const { results, count } = _json;
      const lis = results;
      setLogItems(lis);
      setCount(count);
    }
    fetchData();
  }, [navigate]);

  // const vehicles = vehiclesResults.results
  return (
    <>
      <div className="drivers-page">
        <div className="page-top d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <span className="page-top__title">{`Водители · ${count}`}</span>
            <span className="page-top__left-settings icon-setting transition"></span>
          </div>
          <div className="page-top__right d-flex items-center">
            <div className="page-top__calendar d-flex items-center">
              <div className="page-top__calendar-icon circle-center">
                <span className="icon-calendar"></span>
              </div>
            </div>

            <div className="page-top__search"></div>

            <div className="page-top__dispatcher d-flex items-center">
              <div className="page-top__dispatcher-icon circle-center">
                <span className="icon-settings-b"></span>
              </div>

              <span className="page-top__dispatcher-text d-flex items-center">
                Диспетчер · <span className="transition">{me?.full_name}</span>{' '}
                <span className="icon-angle-down transition"></span>
              </span>

              <div className="dispatcher-menu">
                <div className="dispatcher-menu__inner">
                  <a href="" className="dispatcher-menu__item transition">
                    Личные данные
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Сменить роль
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Выйти
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="drivers-page__top d-flex items-center"></div>
          <div className="drivers-page__table">
            <table className="drivers-table table">
              <thead className="table__top">
                <tr className="table__top-tr">
                  <th className="table__top-th th-id">
                    <div className="d-flex items-center">
                      <label className="table__checkbox">
                        <input type="checkbox" className="table__checkbox-input" />
                        <div className="table__checkbox-block d-flex block-center transition">
                          <span className="icon-check"></span>
                        </div>
                      </label>
                      Id
                    </div>
                  </th>
                  <th className="table__top-th th-status">Статус</th>
                  <th className="table__top-th th-method">HTTP Method</th>
                  <th className="table__top-th th-request_data">Данные</th>
                </tr>
              </thead>
              <tbody>
                {logItems?.map((logItem) => {
                  return (
                    <tr className="table__row" key={logItem.id}>
                      <td className="table__td td-id">
                        <div className="table__td-inner d-flex items-center">
                          <label className="table__checkbox">
                            <input type="checkbox" className="table__checkbox-input" />
                            <div className="table__checkbox-block d-flex block-center transition">
                              <span className="icon-check"></span>
                            </div>
                          </label>
                          {logItem.id}
                        </div>
                      </td>

                      <td className="table__td td-status">
                        <div className="table__td-inner d-flex items-center">
                          <div className={`td-status__block d-flex items-center`}>
                            {/* classes for statuses: at-client - У клиента, on-base - На базе */}
                            {logItem.status_code}
                          </div>
                        </div>
                      </td>
                      <td className="table__td td-name">
                        <div className="table__td-inner d-flex items-center">
                          <div className={`td-status__block d-flex items-center`}>
                            {/* classes for statuses: at-client - У клиента, on-base - На базе */}
                            {logItem.request_body}
                          </div>
                        </div>
                      </td>
                      <td className="table__td td-name">
                        <div className="table__td-inner d-flex items-center">
                          <div className={`td-status__block d-flex items-center`}>
                            {/* classes for statuses: at-client - У клиента, on-base - На базе */}
                            {logItem.response_body}
                          </div>
                        </div>
                      </td>
                      <td className="table__td td-name">
                        <div className="table__td-inner d-flex items-center">
                          {`${logItem.request_method}`}
                        </div>
                      </td>
                      <td className="table__td td-phone">
                        <div className="table__td-inner d-flex items-center">
                          {logItem.request_path}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
