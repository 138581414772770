import React, { useState, FormEvent } from 'react';
import { Tokens } from '../interfaces/tokens';
import { useNavigate } from 'react-router-dom';
import SETTINGS from '../settings';

function Login() {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await fetch(`${SETTINGS.SITE_URL}/api/token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error('Ошибка входа');
      }

      const tokens: Tokens = await response.json();
      localStorage.setItem('accessToken', tokens.access);
      localStorage.setItem('refreshToken', tokens.refresh);
      navigate('/reqs');
      // Перенаправление на защищенную страницу
    } catch (error) {
      console.error('Ошибка входа', error);
    }
  };

  return (
    <div className="login-page d-flex justify-center items-start">
      <form
        className="login-page__form d-flex flex-wrap items-start content-start"
        onSubmit={handleSubmit}
      >
        <img src="/assets/images/main-logo.svg" alt="logo" className="login-page__form-logo" />
        <span className="login-page__form-title">Войти</span>
        <span className="login-page__form-text">Выполните вход</span>
        <div className="login-page__fields">
          <label className="login-page__field d-flex items-center">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              className="login-page__field-input transition"
            />
            {/* add class is-error */}
            <span className="login-page__field-label transition">Username</span>
            <span className="login-page__field-icon icon-profile"></span>
          </label>
          <label className="login-page__field d-flex items-center">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="login-page__field-input transition"
            />
            {/* add class is-error */}
            <span className="login-page__field-label transition">Password</span>
            <span className="login-page__field-icon icon-unlock"></span>
          </label>
        </div>
        <button className="login-page__form-button d-flex items-center transition" type="submit">
          Войти
        </button>
      </form>
    </div>
  );
}

export default Login;
