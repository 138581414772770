import { AddContainer } from '@/interfaces/container';
import SETTINGS from '@/settings';
import { myFetch } from '@/utils';
import { NavigateFunction } from 'react-router-dom';

export const postContainer = (data: Partial<AddContainer> | null, navigate: NavigateFunction) => {
  return myFetch(
    `${SETTINGS.SITE_URL}/api/v1/containers/`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    navigate
  );
};
