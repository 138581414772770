import { useState, useEffect } from 'react';
import { Vehicle } from '../../interfaces/vehicle';
import SETTINGS from '../../settings';
import { exportFileFatch, myFetch } from '../../utils';
import { NavLink, useNavigate, useOutletContext } from 'react-router-dom';
import { Box, InputAdornment, TextField } from '@mui/material';
import ModalTableFields from '../../components/ModalTableFields';
import { Select } from '../../components/select/Select';
import { downloadFile } from '../../utils/downloadFile';

const vehicleStatusFilterItems = [
  {
    value: 'ALL',
    label: 'Все',
    aggKey: 'count',
  },
  {
    value: 'Свободен',
    label: 'Свободные',
    aggKey: 'count_free',
  },
  {
    value: 'В работе',
    label: 'В работе',
    aggKey: 'count_in_work',
  },
  {
    value: 'В ремонте',
    label: 'В ремонте',
    aggKey: 'count_repair',
  },
];

const STATUS_MAP: any = {
  Свободен: 'ready',
  'В ремонте': 'in-repair',
  'В работе': 'in-work',
};

const vehicleTableFields = [
  {
    mykey: 'status',
    label: 'Статус',
  },
  {
    mykey: 'driver_name',
    label: 'Водитель',
  },
  {
    mykey: 'phone',
    label: 'Телефон',
  },
  {
    mykey: 'sts',
    label: 'СТС',
  },
  {
    mykey: 'pts',
    label: 'ПТС',
  },
  {
    mykey: 'owner',
    label: 'Владелец',
  },
  {
    mykey: 'created_at',
    label: 'Дата',
  },
];

export default function VehicleList() {
  const vs1: Vehicle[] = [];
  const [vehicles, setVehicles] = useState(vs1);
  // const [count, setCount] = useState(0)
  const [query, setQuery] = useState('');

  const { me }: { me: any } = useOutletContext();

  const [currentStatus, setCurrentStatus] = useState('ALL');

  const cnts: any = {
    count: 0,
    count_free: 0,
    count_in_work: 0,
    count_repair: 0,
  };
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = (): void => setIsModalOpen(true);
  const closeModal = (): void => setIsModalOpen(false);

  const [counts, setCounts] = useState(cnts);

  const tableFieldsString = localStorage.getItem('vehicleTableFields');
  let tableFieldsObj;
  if (tableFieldsString) {
    tableFieldsObj = JSON.parse(tableFieldsString);
  } else {
    tableFieldsObj = {};
  }
  const [tableFieldIsClosed, setTableFieldIsClosed]: [any, (state: any) => void] =
    useState(tableFieldsObj);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/vehicles/?query=${query}&status=${currentStatus}`,
        {},
        navigate
      );
      const _json = await res.json();
      const { results, count, count_free, count_repair, count_in_work } = _json;
      const vs: Vehicle[] = results;
      setVehicles(vs);
      setCounts({
        count,
        count_free,
        count_repair,
        count_in_work,
      });
    }
    fetchData();
  }, [navigate, currentStatus, query]);

  const handleExportFile = async () => {
    const params = {
      status: currentStatus,
    };
    const path = location.pathname.replace(/\/+/g, '');
    const response = await exportFileFatch(path, navigate, params);
    try {
      await downloadFile(response, path, 'xlsx');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  return (
    <>
      <div className="cars-page">
        <ModalTableFields
          state={tableFieldIsClosed}
          setState={setTableFieldIsClosed}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          tableFields={vehicleTableFields}
        />

        <div className="page-top d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <span className="page-top__title">Автомашины · {counts.count}</span>
            <span
              className="page-top__left-settings icon-setting transition"
              onClick={openModal}
            ></span>
          </div>

          <div className="page-top__right d-flex items-center">
            <div className="page-top__search">
              <TextField
                size="small"
                variant="outlined"
                value={query}
                onChange={handleChangeQuery}
                placeholder="Поиск автомобиля..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="icon-search"></span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="page-top__dispatcher d-flex items-center">
              <div className="page-top__dispatcher-icon circle-center">
                <span className="icon-settings-b"></span>
              </div>

              <span className="page-top__dispatcher-text d-flex items-center">
                Диспетчер · <span className="transition">{me?.full_name}</span>{' '}
                <span className="icon-angle-down transition"></span>
              </span>

              <div className="dispatcher-menu">
                <div className="dispatcher-menu__inner">
                  <a href="" className="dispatcher-menu__item transition">
                    Личные данные
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Сменить роль
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Выйти
                  </a>
                </div>
              </div>
            </div>

            <NavLink
              to={`/vehicles/add/`}
              className="page-top__button d-flex items-center transition"
            >
              Добавить авто
            </NavLink>
          </div>
        </div>

        <div className="page-content">
          <div className="cars-page__top d-flex items-center justify-between pr-10">
            <div className="cars-page__filter d-flex">
              {vehicleStatusFilterItems.map(
                (item: { value: string; label: string; aggKey: string }) => {
                  const classList =
                    'cars-page__filter-item d-flex items-center transition' +
                    (item.value === currentStatus ? ' is-active' : '');
                  return (
                    <div
                      key={item.label}
                      className={classList}
                      onClick={() => {
                        setCurrentStatus(item.value);
                      }}
                    >{`${item.label} · ${counts[item.aggKey]}`}</div>
                  );
                }
              )}
            </div>
            <Box sx={{ width: '101px' }}>
              <Select
                onChange={handleExportFile}
                itemList={['Экспорт в xlsx']}
                value={''}
                label={'Действия'}
              />
            </Box>
          </div>

          <div className="cars-page__table">
            <table className="reqs-table table">
              <thead className="table__top">
                <tr className="table__top-tr">
                  <th className="table__top-th th-id">
                    <div className="d-flex items-center">
                      <label className="table__checkbox">
                        <input type="checkbox" className="table__checkbox-input" />
                        <div className="table__checkbox-block d-flex block-center transition">
                          <span className="icon-check"></span>
                        </div>
                      </label>
                      id
                    </div>
                  </th>
                  {!tableFieldIsClosed.status && (
                    <th className="table__top-th th-status">Статус</th>
                  )}
                  <th className="table__top-th th-name">Наименование</th>
                  {!tableFieldIsClosed.driver_name && (
                    <th className="table__top-th th-driver">Водитель</th>
                  )}
                  {!tableFieldIsClosed.phone && (
                    <th className="table__top-th th-phone">Номер телефона</th>
                  )}
                  {!tableFieldIsClosed.sts && <th className="table__top-th th-sts">Номер СТС</th>}
                  {!tableFieldIsClosed.pts && <th className="table__top-th th-pts">Номер ПТС</th>}
                  {!tableFieldIsClosed.owner && (
                    <th className="table__top-th th-owner">Cобственник</th>
                  )}
                  {!tableFieldIsClosed.created_at && (
                    <th className="table__top-th th-added">Добавлен</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {vehicles?.map((v) => {
                  return (
                    <tr className="table__row" key={v.id}>
                      <td className="table__td td-id">
                        <div className="table__td-inner d-flex items-center">
                          <label className="table__checkbox">
                            <input type="checkbox" className="table__checkbox-input" />
                            <div className="table__checkbox-block d-flex block-center transition">
                              <span className="icon-check"></span>
                            </div>
                          </label>
                          {v.id}
                        </div>
                      </td>
                      {!tableFieldIsClosed.status && (
                        <td className="table__td td-status">
                          <div className="table__td-inner d-flex items-center">
                            <div
                              className={`td-status__block ${
                                STATUS_MAP[v.status] || 'ready'
                              } d-flex items-center`}
                            >
                              {/* classes for statuses: new - Новая, appointed - Назначен, completed - Завершен, in-work - В работе */}
                              {v.status}
                            </div>
                          </div>
                        </td>
                      )}
                      <td className="table__td td-name">
                        <div className="table__td-inner d-flex items-center">
                          {`${v.brand?.name || 'Неизв'} * ${
                            v.max_volume
                          } кубов * ${v.license_plate}`}
                        </div>
                      </td>
                      {!tableFieldIsClosed.driver_name && (
                        <td className="table__td td-driver">
                          <div className="table__td-inner d-flex items-center">
                            {`${v.driver?.last_name || ''} ${
                              v.driver?.first_name || ''
                            } ${v.driver?.second_name || ''}`}
                          </div>
                        </td>
                      )}
                      {!tableFieldIsClosed.phone && (
                        <td className="table__td td-phone">
                          <div className="table__td-inner d-flex items-center">
                            {/*<a href={`tel:${v.driver?.phone}`}>{v.driver?.phone}</a>*/}
                            {v.driver?.phone}
                          </div>
                        </td>
                      )}
                      {!tableFieldIsClosed.sts && (
                        <td className="table__td td-sts">
                          <div className="table__td-inner d-flex items-center">{v.sts}</div>
                        </td>
                      )}
                      {!tableFieldIsClosed.pts && (
                        <td className="table__td td-pts">
                          <div className="table__td-inner d-flex items-center">{v.pts}</div>
                        </td>
                      )}
                      {!tableFieldIsClosed.owner && (
                        <td className="table__td td-owner">
                          <div className="table__td-inner d-flex items-center">{v.owner?.name}</div>
                        </td>
                      )}
                      {!tableFieldIsClosed.created_at && (
                        <td className="table__td td-added">
                          <div className="table__td-inner d-flex items-center">
                            {v.created_at.slice(0, 10)}
                          </div>
                        </td>
                      )}
                      <a
                        href={`/vehicles/${v.id}/edit/`}
                        className="table__edit circle-center transition"
                      >
                        <span className="icon-edit"></span>
                      </a>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
