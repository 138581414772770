import { SelectForm } from '../../ui/mui/Select/Select';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { myFetch } from '../../utils';
import SETTINGS from '../../settings';
import { useNavigate } from 'react-router-dom';
import { Company } from '../../interfaces/company';
import { SelectOutLabel } from '../../ui/mui/Select/SelectOutLabel';

type Clients = {
  id: number;
  label: string;
};

type ClientSelectProps = {
  callback?: (value: string) => void;
  defaultValue?: string;
  type?: 'outlabel' | 'inlabel';
};

export const ClientSelect: FC<ClientSelectProps> = ({ callback, defaultValue, type }) => {
  const navigate = useNavigate();
  const [clients, setClients] = useState<Clients[] | null>(null);
  const [selectValue, setSelectValue] = useState<string | undefined>(defaultValue);

  useEffect(() => {
    const fetchData = async () => {
      const res = await myFetch(`${SETTINGS.SITE_URL}/api/v1/companies/`, {}, navigate);
      const jsonData = await res.json();
      const companys: Company[] = jsonData.results;

      const clients = companys.map((company) => ({
        id: company.id,
        label: company.name,
      }));
      setClients(clients);
    };
    fetchData();
  }, [navigate]);

  const handleChangeSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const selectValue = event.target.value;
    setSelectValue(selectValue);
    if (callback) {
      callback(selectValue);
    }
  };

  return type === 'inlabel' ? (
    <SelectForm
      w={300}
      label="Клиент"
      defaultValue={selectValue ?? ''}
      onChange={handleChangeSelect}
      list={clients}
    />
  ) : (
    <SelectOutLabel
      label="Выберите клиента"
      list={clients || []}
      w={300}
      placeholder="Выберите наименование компании"
      onChangeId={callback}
    />
  );
};
