const MAX_CACHE_SIZE = 50;

export const cleanupCache = <T>(cache: T) => {
  const cacheKeys = Object.keys(cache as keyof T) as (keyof T)[];
  if (cacheKeys.length > MAX_CACHE_SIZE) {
    const newCache = { ...cache };
    cacheKeys.slice(0, cacheKeys.length - MAX_CACHE_SIZE).forEach((key) => delete newCache[key]);
    return newCache;
  }
  return cache;
};
