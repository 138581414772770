import { TablePagination } from '@mui/material';
import { ChangeEvent, FC, MouseEvent } from 'react';

type State = { count: number; page: number; page_size: number };
type Actions = {
  onChangePage: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onChangePageSize: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};
type PaginationProps = {
  state: State;
  action: Actions;
};

export const Pagination: FC<PaginationProps> = ({ state, action }) => {
  return (
    <TablePagination
      component="div"
      count={state.count}
      page={state.page}
      onPageChange={action.onChangePage}
      rowsPerPage={state.page_size}
      onRowsPerPageChange={action.onChangePageSize}
      labelRowsPerPage={'Строк на странице'}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
    />
  );
};
