import { MenuItem, TextField, styled } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatchers } from '../../hooks/Dispatchers/useDispatchers';
import { CreatedUser } from '../../interfaces/user';

const SelectField = styled(TextField)(() => ({
  width: '190px',
  '.MuiSelect-select': {
    color: '#000 !important',
    fontSize: '12px',
  },
  '.MuiSelect-select:focus': {
    borderRadius: '10px',
  },
  '.MuiInputLabel-root': {
    color: '#B2B3C3 !important',
    opacity: '1 !important ',
    fontSize: '10px',
  },
  '.MuiInputBase-root': {
    backgroundColor: 'transparent',
    border: '1px solid #F0F0F7',
    borderRadius: '10px',

    '.MuiSvgIcon-root': {
      color: '#000 !important',
    },
  },
  '.MuiInputBase-root:before': {
    borderBottom: 0,
  },
}));

type DispatchersProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};
export const Dispatchers: FC<DispatchersProps> = ({ onChange }) => {
  const [dispatchers, setDispatchers] = useState<CreatedUser[] | []>([]);
  const getDispatchers = useDispatchers();

  useEffect(() => {
    const fetching = async () => {
      const response = await getDispatchers();
      setDispatchers(response.results);
    };
    fetching();
  }, [getDispatchers]);

  return (
    <SelectField select label="Ответственный менеджер" variant="filled" onChange={onChange}>
      {dispatchers.map((dispatcher) => (
        <MenuItem key={dispatcher.id} value={dispatcher.id}>
          {dispatcher.full_name}
        </MenuItem>
      ))}
    </SelectField>
  );
};
