import { Box, styled } from '@mui/material';
import { RepairReason } from '../../select/RepairReason';
import { DatePicker } from '../../date-picker/DatePicker';
import { Dispatchers } from '../../select/Dispatchers';
import { ChangeEvent, FC, useState } from 'react';
import { useSendToRepair } from '../../../hooks/Vehicles/useSendToRepair';
import dayjs from 'dayjs';

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 40px 40px 40px',
  minWidth: '670px',
  boxSizing: 'border-box',
  gap: '30px',
}));
const Content = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '590px',
  gap: '10px',
}));

const Buttons = styled('div')(() => ({}));

export type RequestBody = {
  repair_date: string | null;
  vehicle: number | null;
  created_by: number | null;
  reason: number | null;
  driver: number | null;
};
const initialRequestBody: RequestBody = {
  repair_date: null,
  vehicle: null,
  created_by: null,
  reason: null,
  driver: null,
};

type SendRepairProps = {
  id: string;
  closeModal: () => void;
};

export const SendRepair: FC<SendRepairProps> = ({ id, closeModal }) => {
  const sendRepair = useSendToRepair();
  const [requestBody, setReauestBody] = useState<RequestBody>(initialRequestBody);

  const handleChangeReasons = (event: ChangeEvent<HTMLInputElement>) => {
    setReauestBody((prevValue) => ({
      ...prevValue,
      reason: Number(event.target.value),
    }));
  };

  const handleChangeDatePicker = (e: dayjs.Dayjs | null) => {
    setReauestBody((prevValue) => {
      const date = e ? e.format('YYYY-MM-DD') : null;
      return { ...prevValue, repair_date: date };
    });
  };

  const handleChangeMenager = (event: ChangeEvent<HTMLInputElement>) => {
    setReauestBody((prevValue) => ({
      ...prevValue,
      created_by: Number(event.target.value),
    }));
  };

  const handleSendVehicleRepair = () => {
    sendRepair(id, { ...requestBody, vehicle: +id }).then((res) => {
      if (res.status === 'В ремонте') {
        closeModal();
      }
    });
  };

  return (
    <Wrapper>
      <Content>
        <RepairReason onChange={handleChangeReasons} />
        <DatePicker onChange={handleChangeDatePicker} label="Время выхода на линию" />
        <Dispatchers onChange={handleChangeMenager} />
      </Content>
      <Buttons>
        <button
          type="button"
          className="form__buttons-btn form__buttons-btn--primary d-flex items-center transition"
          onClick={handleSendVehicleRepair}
        >
          Сохранить
        </button>
      </Buttons>
    </Wrapper>
  );
};
