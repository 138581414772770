import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { myFetch } from '../../utils';
import SETTINGS from '../../settings';
import { RequestBody } from '../../components/vehicle/send-repair/SendRepair';

export const useSendToRepair = () => {
  const navigate = useNavigate();

  return useCallback(
    async (id: string, body: RequestBody) => {
      const response = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/vehicles/${id}/send_to_repair/`,
        { method: 'POST', body: JSON.stringify(body) },
        navigate
      );
      const jsonData = await response.json();
      return jsonData;
    },
    [navigate]
  );
};
