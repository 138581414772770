import { Alert, Checkbox, FormControlLabel, Snackbar, Stack } from '@mui/material';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Group, InputOutLabal, SelectOutLabel, Title } from '@/ui';
import { useClientSelect, useDisclosure } from '@/hooks';
import { postContainer } from '@/api';
import { SNACKBAR_CONFIG } from '@/constants';
import { AddContainer, CompanyObject } from '@/interfaces';
import { cubeType } from '@/params';
import { ClientSelect } from '@/components/select';
import { SaveCancel } from '@/components/buttons';

export const AddForm = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const { addresses_objects, company } = useClientSelect(
    selectedClientId ? +selectedClientId : null
  );
  const [snackbarOpened, snackbarActions] = useDisclosure(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [formData, setFormData] = useState<Partial<AddContainer> | null>(null);

  const handleChangeData =
    <T extends keyof AddContainer>(field: T) =>
    (value: AddContainer[T]) => {
      setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
    };

  const handleClientChoice = (clientId: string) => {
    setSelectedClientId(clientId);
    handleChangeData('owner')(+clientId);
  };

  const handleObjectChoice = (objectId: string) => {
    const foundObject: CompanyObject | undefined = company?.company_objects.find(
      (object) => object.id == objectId
    );
    if (foundObject) {
      const coordinatesArray = foundObject?.coordinates?.split(',');
      handleChangeData('location')(foundObject.address);
      if (coordinatesArray) {
        handleChangeData('coordinates')({ lng: +coordinatesArray[1], lat: +coordinatesArray[0] });
      } else {
        handleChangeData('coordinates')(undefined);
      }
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await postContainer(formData, navigate);

      if (!response.ok) {
        const errorData = await response.json();
        const keyValues = Object.keys(errorData);
        const errorMessage = keyValues.reduce((acc, curr) => {
          acc += `${curr} - ${errorData[curr]} `;
          return acc;
        }, '');
        throw new Error(errorMessage);
      }
      navigate('/containers/');
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        snackbarActions.open();
        setSnackbarMessage(error.message);
      }
    }
  };

  return (
    <>
      <Snackbar
        open={snackbarOpened}
        autoHideDuration={SNACKBAR_CONFIG.autoHideDuration}
        onClose={snackbarActions.close}
        anchorOrigin={SNACKBAR_CONFIG.anchorOrigin}
      >
        <Alert onClose={snackbarActions.close} severity={'warning'} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <form id="containerAdd" onSubmit={handleSubmit}>
        <Stack gap={5}>
          <Title variant="h4">Добавить контейнер</Title>
          <Group gap={50}>
            <SelectOutLabel
              label="Тип контейнера"
              list={cubeType}
              w={230}
              placeholder="Выберите тип контейнера"
              onChangeValue={(value) => handleChangeData('kind')(value)}
            />
            <InputOutLabal
              label="Номер контейнера"
              placeholder="Введите номер контейнера"
              w={230}
              bg=""
              onChange={(event) => handleChangeData('num')(event.target.value)}
            />
          </Group>
          <FormControlLabel
            sx={{ width: '166px' }}
            control={
              <Checkbox
                onChange={(event) => {
                  const isChecked = event.target.checked;
                  setChecked(isChecked);
                  handleChangeData('is_client_owned')(isChecked);
                }}
              />
            }
            label="Контейнер клиента"
          />

          {checked && (
            <>
              <ClientSelect type="outlabel" callback={handleClientChoice} />
              <SelectOutLabel
                label="Выберите адрес объекта"
                list={addresses_objects}
                onChangeId={handleObjectChoice}
                w={300}
              />
            </>
          )}
          <SaveCancel formId="containerAdd" />
        </Stack>
      </form>
    </>
  );
};
