const driverAddFields = [
  {
    header: 'Личные данные',
    fields: [
      {
        mykey: 'last_name',
        fieldKind: 'text',
        label: 'Фамилия',
      },
      {
        mykey: 'first_name',
        fieldKind: 'text',
        label: 'Имя',
      },
      {
        mykey: 'second_name',
        fieldKind: 'text',
        label: 'Отчество',
      },
    ],
  },
  {
    header: 'Связь',
    fields: [
      {
        mykey: 'phone',
        fieldKind: 'text',
        label: 'Телефон',
      },
      {
        mykey: 'telegram',
        fieldKind: 'text',
        label: 'Телеграм',
      },
      {
        mykey: 'whatsapp',
        fieldKind: 'text',
        label: 'WhatsApp',
      },
      {
        mykey: 'email',
        fieldKind: 'text',
        label: 'Электронная почта',
      },
    ],
  },
  {
    header: 'Водительское удостоверение',
    fields: [
      {
        mykey: 'driving_license_number',
        fieldKind: 'text',
        label: '№ вод. удостоверения',
      },
      {
        mykey: 'driving_license_issue_date',
        fieldKind: 'date',
        label: 'Дата получения вод. удостоверения',
      },
      {
        mykey: 'driving_license_expiry_date',
        fieldKind: 'date',
        label: 'Дата истечения вод. удостоверения',
      },
    ],
  },
];

export default driverAddFields;
