import { useState } from 'react';
import { useParams } from 'react-router-dom';
import MyForm from '../../components/MyForm';
import SETTINGS from '../../settings';

const sendToRepairFields = [
  {
    header: '',
    fields: [
      {
        mykey: 'reason',
        fieldKind: 'dict-select',
        label: 'Причина',
        nameField: 'name',
        url: `${SETTINGS.SITE_URL}/api/v1/vehicles/reasons/`,
      },
      {
        mykey: 'repair_date',
        fieldKind: 'date',
        label: 'Дата ремонта',
      },
      {
        mykey: 'created_by',
        fieldKind: 'dict-select',
        label: 'Диспетчер',
        nameField: 'full_name',
        url: `${SETTINGS.SITE_URL}/api/v1/users/`,
      },
    ],
  },
];

export default function VehicleSendToRepair() {
  const { vehicleId } = useParams();
  const baseErrors: any = {};
  const [errors, setErrors] = useState(baseErrors);
  const baseState: any = { vehicle: vehicleId };
  const [state, setState] = useState(baseState);
  return (
    <>
      <MyForm
        fields={sendToRepairFields}
        state={state}
        setState={setState}
        formUrl={`${SETTINGS.SITE_URL}/api/v1/vehicles/${vehicleId}/send_to_repair/`}
        returnUrl={`/vehicles/${vehicleId}/edit/`}
        method="POST"
        errors={errors}
        setErrors={setErrors}
      />
    </>
  );
}
