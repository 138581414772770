import SETTINGS from '@/settings';
import { IReqService } from '../interfaces/IReqService';
import { myFetch } from '@/utils';
import { NavigateFunction } from 'react-router-dom';
import { ReqQueryParams, ReqResult } from '@/interfaces';

export class ReqService implements IReqService {
  private static BASE_URL = `${SETTINGS.SITE_URL}/api/v1/reqs/`;

  async fetchReqs(params: ReqQueryParams, navigate: NavigateFunction): Promise<ReqResult> {
    this.validateParams(params);
    const queryParams = this.buildQueryParams(params);

    try {
      const response = await this.makeRequest(queryParams, navigate);
      return await response.json();
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  private validateParams(params: ReqQueryParams): void {
    if (params.start_date && params.end_date) {
      if (params.start_date > params.end_date) {
        throw new Error('Start date must be before or equal to end date');
      }
    }
  }

  private buildQueryParams(params: ReqQueryParams): URLSearchParams {
    return new URLSearchParams({ ...params });
  }

  private async makeRequest(queryParams: URLSearchParams, navigate: NavigateFunction) {
    const url = `${ReqService.BASE_URL}?${queryParams.toString()}`;

    const response = await myFetch(url, {}, navigate);

    if (!response) {
      navigate('/login');
      throw new Error('Unauthorized');
    }

    return response;
  }

  private handleError(error: unknown) {
    console.error('Request error:', error);
  }
}
