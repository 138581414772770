import { Stack } from '@mui/material';
import { Title } from '../../../../../ui/mui/Title/Title';
import { Group } from '../../../../../ui/mui/Group/Group';
import { InputOutLabal } from '../../../../../ui/mui/Input/InputOutLabal';
import { SelectOutLabel } from '../../../../../ui/mui/Select/SelectOutLabel';
import { useAddFormContext } from '../../../../../hooks/Context/useAddFormContext';

export const PaymentSection = () => {
  const { updateFormData } = useAddFormContext();
  return (
    <Stack gap={3}>
      <Title variant="h4">Оплата</Title>
      <Group gap={50}>
        <InputOutLabal
          label="Сумма оплаты"
          placeholder="0"
          onChange={(event) => updateFormData('total_sum')(event.target.value)}
          bg=""
        />
        <SelectOutLabel
          list={['Расчетный счет', 'Наличные']}
          label="Тип оплаты"
          placeholder="Выберие тип оплаты"
          w={220}
          onChangeValue={(value) => updateFormData('payment_type')(value)}
        />
        <SelectOutLabel
          list={['Оплачен', 'Не оплачен']}
          label="Статус оплаты"
          placeholder="Выберие статус оплаты"
          w={220}
          onChangeValue={(value) => updateFormData('payment_status')(value)}
        />
      </Group>
    </Stack>
  );
};
