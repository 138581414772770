import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Driver, DriverStatus } from '../../interfaces/driver';
import { myFetch } from '../../utils';
import SETTINGS from '../../settings';
import { useNavigate } from 'react-router-dom';
import { ReqForm } from '../../interfaces/req';

type DriverList = {
  id: number;
  label: string;
};

interface DriversReturn {
  driverList: DriverList[] | [];
  vehicleInfo: string;
  handleChangeDriver: (value: ChangeEvent<HTMLInputElement> | number) => void;
  freeDrivers: DriverStatus[] | null;
}

export const useDrivers = (
  initialDriver?: Driver | undefined,
  setFormData?: React.Dispatch<React.SetStateAction<Partial<ReqForm> | null>>
): DriversReturn => {
  const navigate = useNavigate();
  const [freeDrivers, setFreeDrivers] = useState<DriverStatus[] | null>(null);
  const [currentDriver, setCurrentDriver] = useState<DriverStatus[] | Driver[]>(
    initialDriver ? [initialDriver] : []
  );

  useEffect(() => {
    const fetchData = async () => {
      const res = await myFetch(`${SETTINGS.SITE_URL}/api/v1/drivers?status=Готов`, {}, navigate);
      if (!res.ok) {
        throw new Error(`HTTP error status: ${res.status}`);
      }
      const jsonData = await res.json();
      const drivers: DriverStatus[] = jsonData.results;

      if (initialDriver) {
        setFreeDrivers([...drivers, initialDriver as any]);
      } else {
        setFreeDrivers(drivers);
      }
    };
    fetchData();
  }, [navigate, initialDriver]);

  const handleChangeDriver = useCallback(
    (value: ChangeEvent<HTMLInputElement> | number) => {
      const driverId = typeof value === 'number' ? value : Number(value.target.value);
      if (setFormData) {
        setFormData((prev) => ({
          ...(prev ?? {}),
          driver: driverId,
        }));
      }

      const filteredDriver = freeDrivers?.filter(
        (driver) => driver?.current_vehicle?.driver === driverId
      ) as DriverStatus[];

      setCurrentDriver(filteredDriver);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [freeDrivers]
  );

  const driverList = useMemo(
    () =>
      freeDrivers?.map((driver) => ({
        id: driver.id,
        label: driver.full_name,
      })) ?? [],
    [freeDrivers]
  );

  const vehicleInfo = useMemo(() => {
    if (currentDriver.length === 0) return 'Нет данных';
    const vehicle = currentDriver[0].current_vehicle;
    return `${vehicle?.brand?.name} · ${
      vehicle?.max_volume ?? 'нет данных'
    } · ${vehicle?.license_plate ?? 'нет данных'}`;
  }, [currentDriver]);

  return useMemo(
    () => ({ driverList, vehicleInfo, handleChangeDriver, freeDrivers }),
    [driverList, vehicleInfo, handleChangeDriver, freeDrivers]
  );
};
