type ValidationRule = {
  regex: RegExp;
  message: string;
};

export type ValidationRules<T extends string> = {
  [key in T]: ValidationRule[];
};

type Errors<T extends string> = {
  [key in T]: string[];
};

type Data<T extends string> = {
  [key in T]: string;
};

type ValidateData<T extends string> = (data: Data<T>) => Errors<T>;

export const useValidation = <T extends string>(validationRules: ValidationRules<T>) => {
  const validateField = (value: string, rules: ValidationRule[]) => {
    for (const rule of rules) {
      if (!rule.regex.test(value)) {
        return rule.message;
      }
    }
    return null;
  };

  const validateData: ValidateData<T> = (data: Data<T>) => {
    const newErrors: Errors<T> = {} as Errors<T>;

    for (const field in validationRules) {
      if (data[field]) {
        const error = validateField(data[field], validationRules[field]);
        if (error) {
          newErrors[field] = [error];
        }
      }
    }
    return newErrors;
  };
  return { validateData };
};
