import { styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type RouteStatus = {
  isActive: boolean;
  onClick: () => void;
};
const Box = styled('div')<Pick<RouteStatus, 'isActive'>>(({ isActive }) => ({
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 16px',
  borderRadius: '12px',
  backgroundColor: `${isActive ? '#232323' : '#F6F6F9'}`,
  color: `${isActive ? '#fff' : '#B2B3C3'}`,
  fontSize: '10px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: isActive || '#F1F1F1',
  },
}));

export const BadgeRouteStatus: FC<RouteStatus & PropsWithChildren> = ({
  onClick,
  isActive,
  children,
}) => {
  return (
    <Box isActive={isActive} onClick={onClick}>
      {children}
    </Box>
  );
};
