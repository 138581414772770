const cEditFields = [
  {
    header: 'Тип контейнера',
    fields: [
      {
        mykey: 'kind',
        fieldKind: 'select',
        label: 'Тип контейнера',
        nameField: 'type',
        options: [{ type: '8' }, { type: '15' }, { type: '18' }, { type: '27' }, { type: '35' }],
      },
      {
        mykey: 'num',
        fieldKind: 'text',
        label: 'Номер контейнера',
      },
    ],
  },
  {
    header: 'Дополнительная информация',
    fields: [
      {
        mykey: 'owner.name',
        fieldKind: 'text',
        label: 'Владелец контейнера',
      },
      {
        mykey: 'created_by.full_name',
        fieldKind: 'text',
        label: 'Контейнер добавлен',
      },
      {
        mykey: 'created_at',
        fieldKind: 'text',
        label: 'Дата добавления',
        disabled: true,
      },
    ],
  },
];

export default cEditFields;
