import { container } from '@/core/di/ServiceContainer';
import { ReqQueryParams } from '@/interfaces';
import { IReqService, ReqService } from '@/services';

import { NavigateFunction } from 'react-router-dom';

// Регистрация сервисов
export function registerServices() {
  container.register<IReqService>('ReqService', new ReqService());
}

// Утилита для получения результатов заявок
export async function getReqs(params: ReqQueryParams & { navigate: NavigateFunction }) {
  const { navigate, ...queryParams } = params;
  const reqService = container.resolve<IReqService>('ReqService');
  return reqService.fetchReqs(queryParams, navigate);
}
