import { styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type Group = PropsWithChildren & {
  gap?: number;
  justify?: 'center' | 'start' | 'end' | 'space-between' | 'space-evenly' | 'space-around';
  align?: 'center' | 'start' | 'end';
  w?: number;
  h?: number;
};

const GroupMui = styled('div')<Group>(({ gap, justify, w, h, align }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: `${gap}px`,
  justifyContent: `${justify}`,
  alignItems: `${align}`,
  width: `${w}px`,
  height: `${h}px`,
}));

export const Group: FC<Group> = ({ gap, justify, w, h, align, children }) => {
  return (
    <GroupMui gap={gap} justify={justify} w={w} h={h} align={align}>
      {children}
    </GroupMui>
  );
};
