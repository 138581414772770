import { Stack, styled } from '@mui/material';
import { Title } from '../../../../../ui/mui/Title/Title';
import { ExtraService } from '../../../extra-service/ExtraService';
import { useServices } from '../../../../../hooks/Additional-Service/useServices';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  AdditionalServiceState,
  Additional_services,
  ReqForm,
} from '../../../../../interfaces/req';
import { useServiceSelect } from '../../../../../hooks/Reqs/useServiceSelect';

const ButtonAddService = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '24px',
  width: '130px',
  borderRadius: '12px',
  border: '1px solid #44A77D',
  color: '#44A77D',
  fontWeight: 600,
  fontSize: '12px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

type ServiceSectionProps = {
  initialState: Additional_services[];
  isFinishedStatus: boolean;
  onChange: (field: keyof ReqForm) => (value: any) => void;
};

export const ServiceSection: FC<ServiceSectionProps> = ({
  initialState,
  isFinishedStatus,
  onChange,
}) => {
  const { services, addService, removeService } = useServices(initialState);
  const { serviceSelectData } = useServiceSelect();
  const [serviceFormState, setServiceFormState] = useState<AdditionalServiceState[]>([]);

  const handleChangeService = useCallback(
    (service: AdditionalServiceState) => {
      setServiceFormState([...serviceFormState, service]);
    },
    [serviceFormState]
  );

  useEffect(() => {
    if (serviceFormState.length === 0) return;
    onChange('additional_services')(serviceFormState);
  }, [serviceFormState, onChange]);

  return (
    <Stack gap={2}>
      <Title variant="h4">Дополнительные услуги</Title>
      <Stack gap={2}>
        {services.map((service) => (
          <ExtraService
            key={service.id}
            selecData={serviceSelectData}
            serviceData={service}
            onRemove={removeService}
            disabled={isFinishedStatus}
            callback={handleChangeService}
          />
        ))}
        {!isFinishedStatus && (
          <ButtonAddService onClick={addService}>Добавить услугу</ButtonAddService>
        )}
      </Stack>
    </Stack>
  );
};
