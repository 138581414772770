import { CubeType } from '../interfaces/container';

export const containerStatusFilterItems = [
  {
    value: 'ALL',
    label: 'Все',
    aggKey: 'count',
  },
  {
    value: 'У клиента',
    label: 'У клиента',
    aggKey: 'count_at_client',
  },
  {
    value: 'На базе',
    label: 'На базе',
    aggKey: 'count_at_base',
  },
];

export const CONTAINER_STATUS_MAP: any = {
  'На базе': 'on-base',
  'У клиента': 'at-client',
};

export const containerVolumeFilterItems = [
  // {
  //     value: 'ALL',
  //     valueMin: 0,
  //     valueMax: 101,
  //     label: 'Все',
  //     aggKey: 'count',
  // },
  {
    value: '0_4',
    valueMin: 0,
    valueMax: 4,
    label: 'до 4 кубов',
    aggKey: 'count_0_4',
  },
  {
    value: '4_8',
    valueMin: 4,
    valueMax: 8,
    label: '4 - 8 кубов',
    aggKey: 'count_4_8',
  },
  {
    value: '8_20',
    valueMin: 8,
    valueMax: 20,
    label: '8 - 20 кубов',
    aggKey: 'count_8_20',
  },
];

export const cubeType: CubeType[] = ['8', '15', '18', '27', '35'];
