import SETTINGS from '@/settings';
import { Link, styled } from '@mui/material';
import { FC } from 'react';

type ImageLinkProps = {
  href: string;
  w?: number;
  h?: number;
  radius?: number;
};
const Img = styled('img')<Pick<ImageLinkProps, 'h' | 'w' | 'radius'>>(({ w, h, radius }) => ({
  width: `${w}px`,
  height: `${h}px`,
  borderRadius: `${radius}px`,
  cursor: 'zoom-in',
}));

export const ImageLink: FC<ImageLinkProps> = ({ href, ...props }) => {
  return (
    <Link href={`${SETTINGS.SITE_URL}${href}`} target="_blank" rel="noreferrer">
      <Img
        srcSet={`${SETTINGS.SITE_URL}${href}?w=${props.w}&h=${props.h}&fit=crop&auto=format&dpr=2 2x`}
        src={`${SETTINGS.SITE_URL}${href}?w=${props.w}&h=${props.h}&fit=crop&auto=format`}
        alt={href}
        loading="lazy"
        {...props}
      />
    </Link>
  );
};
