import { Stack, styled } from '@mui/material';
import { Title } from '../../../../../ui/mui/Title/Title';
import { useServices } from '../../../../../hooks/Additional-Service/useServices';
import { ExtraService } from '../../../extra-service/ExtraService';
import { useServiceSelect } from '../../../../../hooks/Reqs/useServiceSelect';
import { AdditionalServiceState } from '../../../../../interfaces/req';
import { useAddFormContext } from '../../../../../hooks/Context/useAddFormContext';
import { useEffect, useState } from 'react';

const ButtonAddService = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '24px',
  width: '130px',
  borderRadius: '12px',
  border: '1px solid #44A77D',
  color: '#44A77D',
  fontWeight: 600,
  fontSize: '12px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

export const ServiceSection = () => {
  const { services, addService, removeService } = useServices();
  const { serviceSelectData } = useServiceSelect();
  const [serviceFormState, setServiceFormState] = useState<AdditionalServiceState[]>([]);

  const { updateFormData } = useAddFormContext();

  const handleAddService = (service: AdditionalServiceState) => {
    setServiceFormState([...serviceFormState, service]);
  };

  useEffect(() => {
    if (serviceFormState.length > 0) {
      updateFormData('additional_services')(serviceFormState);
    }
  }, [serviceFormState, updateFormData]);

  return (
    <Stack gap={3}>
      <Title variant="h4">Дополнительные услуги</Title>
      <Stack gap={2}>
        {services.map((service) => (
          <ExtraService
            key={service.id}
            selecData={serviceSelectData}
            serviceData={service}
            onRemove={removeService}
            callback={handleAddService}
          />
        ))}

        <ButtonAddService onClick={addService}>Добавить услугу</ButtonAddService>
      </Stack>
    </Stack>
  );
};
