import { RouteSection } from './sections/RouteSection';
import { useAddFormContext } from '../../../../hooks/Context/useAddFormContext';
import { ClientSection } from './sections/ClientSection';
import { Alert, Snackbar, Stack } from '@mui/material';
import { ActionSection } from './sections/ActionSection';
import { ExecuterSection } from './sections/ExecuterSection';
import { PaymentSection } from './sections/PaymentSection';
import { CommentSection } from './sections/CommentSection';
import { ServiceSection } from './sections/ServiceSection';
import { SaveCancel } from '../../../buttons/SaveCancel';
import { SNACKBAR_CONFIG } from '../../../../constants/constants';
import { useDisclosure } from '../../../../hooks/useDisclosure';

import { FormEvent, useMemo, useRef } from 'react';
import { FormConfirmModals } from '../../../../interfaces/req';
import { useConfirmModals } from '../../../../hooks/Modals/useConfirmModals';
import { EditConfirmModals } from '../../../modals/modal-confirm/EditConfirmModals';
import { useNavigate } from 'react-router-dom';
import { MODAL_EDIT_CONFIGS } from '@/params/modal';

export const AddForm = () => {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement | null>(null);
  const { snackbarMessage, handleSubmit, formData, isSuccess } = useAddFormContext();
  const [snackbarOpened, snackbarActions] = useDisclosure(false);
  const { modalStates, toggleModal } = useConfirmModals();

  const modalConfirmConfig: FormConfirmModals = useMemo(
    () => ({
      configs: MODAL_EDIT_CONFIGS,
      states: modalStates,
      handlers: {
        createReq_confirm: {
          onConfirm: () => {
            handleConfirm();
          },
          onClose: () => {
            toggleModal('createReq_confirm');
            navigate('/reqs/');
          },
        },
      },
    }),
    [modalStates, navigate, toggleModal]
  );

  function handleConfirm() {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { bubbles: true }));
    }
  }
  const handleCancel = () => {
    if (
      (formData?.client && formData.address_location) ||
      formData?.req_date ||
      (formData?.driver && formData.container)
    ) {
      toggleModal('createReq_confirm');
    } else {
      navigate('/reqs/');
    }
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    handleSubmit(event, snackbarActions.open);
  };

  return (
    <form ref={formRef} onSubmit={handleFormSubmit} id="reqAdd">
      <Stack gap={4}>
        <Snackbar
          open={snackbarOpened}
          autoHideDuration={SNACKBAR_CONFIG.autoHideDuration}
          onClose={snackbarActions.close}
          anchorOrigin={SNACKBAR_CONFIG.anchorOrigin}
        >
          <Alert
            onClose={snackbarActions.close}
            severity={isSuccess ? 'success' : 'warning'}
            variant="filled"
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <EditConfirmModals {...modalConfirmConfig} />
        <RouteSection />
        <ClientSection />
        <ActionSection />
        <ExecuterSection />
        <PaymentSection />
        <CommentSection />
        <ServiceSection />
        <SaveCancel formId="reqAdd" onCancel={handleCancel} />
      </Stack>
    </form>
  );
};
