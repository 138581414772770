import { useNavigate } from 'react-router-dom';
import SETTINGS from '../../settings';
import { myFetch } from '../../utils';
import { useCallback } from 'react';

const URL = `${SETTINGS.SITE_URL}/api/v1/users/`;

export const useDispatchers = () => {
  const navigate = useNavigate();

  return useCallback(async () => {
    const response = await myFetch(URL, {}, navigate);
    const jsonData = await response.json();
    return jsonData;
  }, [navigate]);
};
