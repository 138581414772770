import { useEffect, useMemo, useState } from 'react';
import SETTINGS from '../../settings';
import MyForm from '../../components/MyForm';
import { exportFileFatch, myFetch } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadFile } from '../../utils/downloadFile';
import { Badge, Box } from '@mui/material';
import { Select } from '../../components/select/Select';
import { FormConfirmModals, Req } from '../../interfaces/req';
import { getDateFormat } from '../../utils/getDateFormat';
import driverEditFields from '@/form-fields/driver-edit';
import { Driver } from '@/interfaces/driver';
import { getDayEnding } from '@/utils/getDatEnding';
import { EditConfirmModals } from '@/components/modals/modal-confirm/EditConfirmModals';
import { useConfirmModals } from '@/hooks/Modals/useConfirmModals';
import { MODAL_EDIT_CONFIGS } from '@/params/modal';
import { DateCalendare } from '@/components/date-picker/DateCalendar';

export default function DriverEdit() {
  const navigate = useNavigate();
  const { modalStates, toggleModal } = useConfirmModals();
  const { driverId } = useParams();
  const [driver, setDriver] = useState<Driver | null>(null);
  const baseErrors: any = {};
  const [errors, setErrors] = useState(baseErrors);
  const [driverReqs, setDriverReqs] = useState<Req[]>();

  useEffect(() => {
    const fetchData = async () => {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/reqs?driver_id=${driverId}`,
        {},
        navigate
      );
      const jsonData = await res.json();
      setDriverReqs(jsonData.results);
    };
    fetchData();
  }, [navigate, driverId]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await myFetch(`${SETTINGS.SITE_URL}/api/v1/drivers/${driverId}/`, {}, navigate);
      setDriver(await res.json());
    };
    fetchData();
  }, [driverId, navigate]);

  const handleExportFile = async () => {
    const params = {
      driver_id: driverId!,
    };

    const response = await exportFileFatch('reqs', navigate, params);
    try {
      await downloadFile(response, `reqs_history${driverId}`, 'xlsx');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDismissDriver = async () => {
    try {
      const response = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/drivers/${driverId}/dismiss/`,
        { method: 'PATCH' },
        navigate
      );
      if (!response.ok) throw new Error('Не получилось удалить водителя');
    } catch (error) {
      console.error(error);
    }
  };
  const modalConfirmConfig: FormConfirmModals = useMemo(
    () => ({
      configs: {
        driverfire_confirm: {
          title: `${MODAL_EDIT_CONFIGS.driverfire_confirm?.title} ${driver?.full_name}?`,
          confirmLabel: MODAL_EDIT_CONFIGS.driverfire_confirm?.confirmLabel as string,
        },
      },
      states: { driverfire_confirm: modalStates.driverfire_confirm },
      handlers: {
        driverfire_confirm: {
          onConfirm: () => {
            handleDismissDriver();
            navigate('/drivers');
          },
          onClose: () => toggleModal('driverfire_confirm'),
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalStates.driverfire_confirm, toggleModal]
  );

  return (
    <>
      <div className="driver-page d-flex flex-wrap">
        <div className="page-top page-top--black d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <div className="page-top__back circle-center" onClick={() => navigate(-1)}>
              <span className="icon-angle-left"></span>
            </div>
            <span className="page-top__title">{`Водитель ID ${driver?.id}`}</span>
            <div className="page-top__status weekend d-flex items-center">{driver?.status}</div>
          </div>

          <div className="page-top__right d-flex items-center">
            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-check-circle"></span>
              </div>
              Заявок завершено <b>· {driver?.finished_reqs_count}</b>
            </div>

            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-calendar"></span>
              </div>
              Выходы на линию{' '}
              <b>
                · {driver?.on_line} {getDayEnding(driver?.on_line ?? 0)}{' '}
              </b>
            </div>

            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-truck"></span>
              </div>
              Авто {'  '}
              <b>
                {driver?.current_vehicle
                  ? `· ${driver?.current_vehicle?.brand?.name} · ${driver?.current_vehicle?.max_volume}
                кубов · ${driver?.current_vehicle?.license_plate}`
                  : '  - не назначен'}
              </b>
            </div>

            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-phone"></span>
              </div>
              Телефон <b>· {driver?.phone}</b>
            </div>
          </div>
        </div>

        <div className="driver-page__content">
          <EditConfirmModals {...modalConfirmConfig} />
          <MyForm
            fields={driverEditFields}
            method="PUT"
            returnUrl={`/drivers/`}
            formUrl={`${SETTINGS.SITE_URL}/api/v1/drivers/${driverId}/`}
            state={driver ?? {}}
            setState={setDriver}
            errors={errors}
            setErrors={setErrors}
            callback={() => toggleModal('driverfire_confirm')}
          />
        </div>

        <div className="driver-page__schedule">
          <h2 className="form__title">График работы</h2>
          <div className="driver-page__schedule-calendar">
            <DateCalendare />
          </div>
          <div className="details-list d-flex flex-wrap justify-between">
            <div className="details-list__item d-flex flex-column justify-center">
              <span className="details-list__item-title">Всего заявок</span>
              <span className="details-list__item-value">{driver?.count_reqs}</span>
              <span className="details-list__item-icon icon-routing"></span>
            </div>

            <div className="details-list__item d-flex flex-column justify-center">
              <span className="details-list__item-title">Заявок в работе</span>
              <span className="details-list__item-value">{driver?.count_reqs_in_work}</span>
              <span className="details-list__item-icon icon-refresh-circle"></span>
            </div>

            <div className="details-list__item d-flex flex-column justify-center">
              <span className="details-list__item-title">Завершено смен</span>
              <span className="details-list__item-value">{driver?.count_completed_shifts}</span>
              <span className="details-list__item-icon icon-building"></span>
            </div>

            <div className="details-list__item d-flex flex-column justify-center">
              <Badge badgeContent={'в работе'} color="warning">
                <span className="details-list__item-title">Закрыто часов</span>
              </Badge>
              <span className="details-list__item-value">-</span>

              <span className="details-list__item-icon icon-box"></span>
            </div>
          </div>
        </div>

        <div className="driver-page__table">
          <div className="driver-page__table-top d-flex justify-between items-center">
            <h2 className="form__title">Заявки водителя</h2>
            <Box style={{ width: '101px' }}>
              <Select
                onChange={handleExportFile}
                itemList={['Экспорт в xlsx']}
                value={''}
                label={'Действия'}
              />
            </Box>
          </div>

          <table className="driver-table table">
            <thead className="table__top">
              <tr className="table__top-tr">
                <th className="table__top-th th-id">
                  <div className="d-flex items-center">
                    <label className="table__checkbox">
                      <input type="checkbox" className="table__checkbox-input" />
                      <div className="table__checkbox-block d-flex block-center transition">
                        <span className="icon-check"></span>
                      </div>
                    </label>
                    Id
                  </div>
                </th>
                <th className="table__top-th th-status">Статус</th>
                <th className="table__top-th th-payment">Оплата</th>
                <th className="table__top-th th-client">Клиент</th>
                <th className="table__top-th th-address">Адрес объекта</th>
                <th className="table__top-th th-phone">Номер телефона</th>
                <th className="table__top-th th-driver">Водитель</th>
                <th className="table__top-th th-car">Автомашина</th>
                <th className="table__top-th th-date">Дата заявки</th>
                <th className="table__top-th th-type">Тип заявки</th>
              </tr>
            </thead>
            <tbody>
              {driverReqs?.map((req) => (
                <tr className="table__row" key={req.id}>
                  <td className="table__td td-id">
                    <div className="table__td-inner d-flex items-center">
                      <label className="table__checkbox">
                        <input type="checkbox" className="table__checkbox-input" />
                        <div className="table__checkbox-block d-flex block-center transition">
                          <span className="icon-check"></span>
                        </div>
                      </label>
                      {req.id}
                    </div>
                  </td>
                  <td className="table__td td-status">
                    <div className="table__td-inner d-flex items-center">
                      <div className="td-status__block in-work d-flex items-center">
                        {/* classes for statuses: in-work - В работе, completed - Завершен */}
                        {req.status}
                      </div>
                    </div>
                  </td>
                  <td className="table__td td-payment">
                    <div className="table__td-inner d-flex items-center">
                      <div className="td-payment__block unpaid d-flex items-center">
                        {/* classes for statuses: unpaid - Неоплачен, paid - Оплачен */}
                        {req.payment_status}
                      </div>
                    </div>
                  </td>
                  <td className="table__td td-client">
                    <div className="table__td-inner d-flex items-center">{req.client?.name}</div>
                  </td>
                  <td className="table__td td-address">
                    <div className="table__td-inner d-flex items-center">
                      {req.address?.location}
                    </div>
                  </td>
                  <td className="table__td td-phone">
                    <div className="table__td-inner d-flex items-center">
                      <a href={`tel:${req.client?.phone}`}>{req.client?.phone}</a>
                    </div>
                  </td>
                  <td className="table__td td-driver">
                    <div className="table__td-inner d-flex items-center">
                      {req.driver?.full_name}
                    </div>
                  </td>
                  <td className="table__td td-car">
                    <div className="table__td-inner d-flex items-center">
                      {req.vehicle?.license_plate}
                    </div>
                  </td>
                  <td className="table__td td-date">
                    <div className="table__td-inner d-flex items-center">
                      {getDateFormat(req.req_date, {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </div>
                  </td>
                  <td className="table__td td-type">
                    <div className="table__td-inner d-flex items-center">{req.kind}</div>
                  </td>
                  <a href="" className="table__move circle-center transition">
                    <span className="icon-arrow-right transition"></span>
                  </a>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
