import { MenuItem, TextField, styled } from '@mui/material';
import { useReasons } from '../../hooks/Vehicles/useReasons';
import { ChangeEvent, FC, useEffect, useState } from 'react';

type Reasons = {
  id: number;
  label: string;
  value: string;
};

const SelectField = styled(TextField)(() => ({
  minWidth: '100%',
  '.MuiSelect-select': {
    color: '#000 !important',
    fontSize: '12px',
  },
  '.MuiSelect-select:focus': {
    borderRadius: '10px',
  },
  '.MuiInputLabel-root': {
    color: '#B2B3C3 !important',
    opacity: '1 !important ',
    fontSize: '10px',
  },
  '.MuiInputBase-root': {
    backgroundColor: 'transparent',
    border: '1px solid #F0F0F7',
    borderRadius: '10px',

    '.MuiSvgIcon-root': {
      color: '#000 !important',
    },
  },
  '.MuiInputBase-root:before': {
    borderBottom: 0,
  },
}));

type RepairReasonProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};
export const RepairReason: FC<RepairReasonProps> = ({ onChange }) => {
  const [reasons, setReasons] = useState<Reasons[] | []>([]);
  const getReasons = useReasons();

  useEffect(() => {
    const fetching = async () => {
      const response = await getReasons();
      const mod_data = response.results.map((result: { name: any }) => ({
        ...result,
        value: result.name,
        label: result.name,
      }));
      setReasons(mod_data);
    };
    fetching();
  }, [getReasons]);

  return (
    <SelectField select label="Причина" variant="filled" onChange={onChange}>
      {reasons.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.label}
        </MenuItem>
      ))}
    </SelectField>
  );
};
