export const downloadFile = async (
  response: Response,
  file_name: string,
  format: string
): Promise<void> => {
  if (!response.ok) {
    throw new Error(`Error ${response.status}: ${response.statusText}`);
  }
  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = `exported_file_${file_name}.${format}`;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
