import { useEffect, useState, useMemo, useCallback } from 'react';
import { Snackbar } from '@mui/material';
import SETTINGS from '../../settings';
import { myFetch } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useDisclosure } from '../../hooks/useDisclosure';
import { EditReqForm } from '../../components/req/form/edit-form/EditForm';
import { FormConfirmModals, Req } from '../../interfaces/req';
import { getDateFormat } from '../../utils/getDateFormat';
import { getTime } from '../../utils/getTime';

import { EditConfirmModals } from '../../components/modals/modal-confirm/EditConfirmModals';

import { DATE_FORMAT_OPTIONS, SNACKBAR_CONFIG } from '../../constants/constants';
import { ModalKeys, useConfirmModals } from '../../hooks/Modals/useConfirmModals';
import { MODAL_EDIT_CONFIGS } from '@/params/modal';

const historyDataMappings: { [key: string]: { title: string; class: string } } = {
  kind: { title: 'Изменения типа маршрута', class: 'history-item--orange' },
  total_sum: {
    title: 'Изменение суммы оплаты',
    class: 'history-item--orange',
  },
  driver: { title: 'Назначение водителя', class: 'history-item--orange' },
  container: { title: 'Изменения контейнера', class: 'history-item--orange' },
  req_date: { title: 'Изменения даты заявки', class: 'history-item--orange' },
  status: { title: 'Отменена', class: 'history-item--orange' },
};

export default function ReqEdit() {
  const navigate = useNavigate();
  const { reqId } = useParams();
  const [req, setReq] = useState<Req | null>(null);
  const rh: any[] = [];
  const [reqHistory, setReqHistory] = useState(rh);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpened, snackbarActions] = useDisclosure(false);
  const { modalStates, toggleModal } = useConfirmModals();

  useEffect(() => {
    const fetchData = async () => {
      const res = await myFetch(`${SETTINGS.SITE_URL}/api/v1/reqs/${reqId}/`, {}, navigate);
      const res2 = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/reqs/${reqId}/history/`,
        {},
        navigate
      );
      setReq(await res.json());
      setReqHistory(await res2.json());
    };
    fetchData();
  }, [reqId, navigate]);

  const handleCancelRequest = useCallback(
    async (modal: ModalKeys) => {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/reqs/${reqId}/`,
        { method: 'PATCH', body: JSON.stringify({ status: 'Отменена' }) },
        navigate
      );
      if (!res.ok) {
        const jsonData = await res.json();
        snackbarActions.open();
        setSnackbarMessage(`Ошибка: ${Object.values(jsonData)[0] as string}`);
        toggleModal(`${modal}_confirm` as ModalKeys);
        toggleModal(modal);
      } else {
        navigate('/reqs');
      }
    },
    [navigate, toggleModal, reqId, snackbarActions]
  );

  const modalConfirmConfig: FormConfirmModals = useMemo(
    () => ({
      configs: MODAL_EDIT_CONFIGS,
      states: modalStates,
      handlers: {
        refuse: {
          onConfirm: () => toggleModal('refuse_confirm'),
          onClose: () => toggleModal('refuse'),
        },
        refuse_confirm: {
          onConfirm: () => handleCancelRequest('refuse'),
          onClose: () => {
            toggleModal('refuse_confirm');
            toggleModal('refuse');
          },
        },
        idleFlight: {
          onConfirm: () => toggleModal('idleFlight_confirm'),
          onClose: () => toggleModal('idleFlight'),
        },
        idleFlight_confirm: {
          onConfirm: () => handleCancelRequest('idleFlight'),
          onClose: () => {
            toggleModal('idleFlight_confirm');
            toggleModal('idleFlight');
          },
        },
        overloading: {
          onConfirm: () => toggleModal('overloading_confirm'),
          onClose: () => toggleModal('overloading'),
        },
        overloading_confirm: {
          onConfirm: () => handleCancelRequest('overloading'),
          onClose: () => {
            toggleModal('overloading_confirm');
            toggleModal('overloading');
          },
        },
      },
    }),
    [modalStates, handleCancelRequest, toggleModal]
  );

  if (!req) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Snackbar
        open={snackbarOpened}
        autoHideDuration={SNACKBAR_CONFIG.autoHideDuration}
        onClose={snackbarActions.close}
        anchorOrigin={SNACKBAR_CONFIG.anchorOrigin}
        message={snackbarMessage}
      />
      <EditConfirmModals {...modalConfirmConfig} />
      <div className="application-page d-flex flex-wrap">
        <div className="page-top page-top--black d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <div className="page-top__back circle-center" onClick={() => navigate(-1)}>
              <span className="icon-angle-left"></span>
            </div>
            <span className="page-top__title">Заявка #{req.id}</span>
            <div className="page-top__status completed d-flex items-center">{req.status}</div>
            {/* Завершен - completed, В работе - in-work */}
          </div>

          <div className="page-top__right d-flex items-center">
            <div className="page-top__created d-flex items-center">
              <div className="page-top__created-icon circle-center">
                <span className="icon-calendar"></span>
              </div>
              <span className="page-top__created-date">
                Создана от {getDateFormat(req.created_at, DATE_FORMAT_OPTIONS)}·{' '}
                {getTime(req.created_at)}
              </span>
              <span className="page-top__created-editing">
                Изменена {getDateFormat(req.updated_at, DATE_FORMAT_OPTIONS)}·{' '}
                {getTime(req.updated_at)}
              </span>
            </div>

            <div className="truck-info d-flex items-center">
              <div className="truck-info__icon circle-center">
                <span className="icon-truck"></span>
              </div>

              <span className="truck-info__text d-flex items-center">
                Авто <span>· KAMAZ · 8 кубов · {req.driver?.current_vehicle?.license_plate}</span>
              </span>
            </div>

            <div className="spent-fuel d-flex items-center">
              <div className="spent-fuel__icon circle-center">
                <span className="icon-drop"></span>
              </div>
              <span className="spent-fuel__text">
                Топливо потрачено <span>· 308 литров</span>
              </span>
            </div>
          </div>
        </div>

        <div className="application-page__top d-flex">
          <div
            className="application-page__top-item d-flex items-center transition"
            onClick={() =>
              navigate('/', {
                state: {
                  key: 'req',
                  data: { req_id: req.id },
                },
              })
            }
          >
            <span className="icon-map transition"></span>
            Посмотреть на карте
          </div>
          <div
            className="application-page__top-item d-flex items-center transition"
            onClick={() => toggleModal('refuse')}
          >
            <span className="icon-close-circle transition"></span>
            Отказ от заявки
          </div>
          <div
            className="application-page__top-item d-flex items-center transition"
            onClick={() => toggleModal('idleFlight')}
          >
            <span className="icon-danger transition"></span>
            Холостой рейс
          </div>
          <div
            className="application-page__top-item d-flex items-center transition"
            onClick={() => toggleModal('overloading')}
          >
            <span className="icon-cube transition"></span>
            Перегруз автотранспорта
          </div>
        </div>

        <div className="application-page__content">
          <EditReqForm req={req} />
        </div>

        <div className="application-page__history">
          <h2 className="form__title">История заявки</h2>
          <div className="history-list">
            <div className="history-item history-item--green">
              {/* add class 'history-item--green' or 'history-item--orange' */}
              <div className="history-item__inner d-flex flex-wrap">
                <div className="history-item__info d-flex justify-between">
                  <div className="history-item__info-column d-flex flex-column">
                    <span className="history-item__info-text">
                      {getDateFormat(req.created_at, {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                      })}
                      · {getTime(req.created_at)}
                    </span>
                    <span className="history-item__info-title">Создание заявки</span>
                  </div>
                  <div className="history-item__info-column d-flex flex-column">
                    <span className="history-item__info-text">Менеджер</span>
                    <span className="history-item__info-manager d-flex items-center">
                      {req.author?.full_name || '<Неизвестный>'}
                      <span className="icon-profile-black"></span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {reqHistory.map((hist, i) => {
              let title = 'Неизвестное действие';
              let myClass = 'history-item--red';

              const historyDataKeys = Object.keys(hist.history_data);

              for (const key of historyDataKeys) {
                if (historyDataMappings[key]) {
                  const mapping = historyDataMappings[key];

                  if (hist.history_data.status) {
                    const splitValue = hist.history_data.status.split(' -> ');
                    title = `Статус - "${splitValue[1]}"`;
                    myClass = historyDataMappings[key].class;
                  } else {
                    title = mapping.title;
                  }
                  myClass = mapping.class;
                  break;
                }
              }
              return (
                hist && (
                  <div className={`history-item ${myClass}`} key={i}>
                    {/* add class 'history-item--green' or 'history-item--orange' */}
                    <div className="history-item__inner d-flex flex-wrap">
                      <div className="history-item__info d-flex justify-between">
                        <div className="history-item__info-column d-flex flex-column">
                          <span className="history-item__info-text">
                            {getDateFormat(hist.created_at, DATE_FORMAT_OPTIONS)}·{' '}
                            {getTime(hist.created_at)}
                          </span>
                          <span className="history-item__info-title">{title}</span>
                        </div>
                        <div className="history-item__info-column d-flex flex-column">
                          <span className="history-item__info-text">Менеджер</span>
                          <span className="history-item__info-manager d-flex items-center">
                            {hist.changer?.full_name}
                            <span className="icon-profile-black"></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
