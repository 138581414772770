import { FC, PropsWithChildren, createContext, useCallback, useEffect } from 'react';
import { useReqAddForm } from '../../hooks/Reqs/useReqAddForm';
import { ReqForm } from '../../interfaces/req';
import { useOutletContext } from 'react-router-dom';

interface FormContextType {
  formData: Partial<ReqForm> | null;
  updateFormData: (label: keyof ReqForm) => (value: any) => void;
  isSuccess: boolean;
  snackbarMessage: string;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>, onOpenModal: () => void) => Promise<void>;
}

export const FormContext = createContext<FormContextType | null>(null);

export const FormProvider: FC<PropsWithChildren> = ({ children }) => {
  const { me }: { me: any } = useOutletContext();
  const { formData, setFormData, snackbarMessage, handleSubmit, isSuccess } = useReqAddForm();

  const updateFormData = useCallback(
    (field: keyof ReqForm) => (value: any) => {
      setFormData((prev) => ({ ...prev, [field]: value }) as ReqForm);
    },
    [setFormData]
  );

  useEffect(() => {
    if (me?.id) {
      updateFormData('dispatcher1')(me.id);
    }
  }, [me.id, updateFormData]);

  return (
    <FormContext.Provider
      value={{
        formData,
        updateFormData,
        snackbarMessage,
        handleSubmit,
        isSuccess,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
