import { Stack } from '@mui/material';
import { Title } from '../../../../../ui/mui/Title/Title';
import { InputForm } from '../../../../../ui/mui/Input/Input';
import { useAddFormContext } from '../../../../../hooks/Context/useAddFormContext';
import { ChangeEvent, useState } from 'react';
import { ERROR_MESSAGES } from '../../../../../constants/constants';

const MAX_LENGTH = 400;

export const CommentSection = () => {
  const { updateFormData } = useAddFormContext();
  const [isLimit, setLimit] = useState<boolean>(false);

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.length > MAX_LENGTH) {
      setLimit(true);
    } else {
      setLimit(false);
      updateFormData('comment')(inputValue);
    }
  };

  return (
    <Stack gap={3}>
      <Title variant="h4">Комментарий</Title>
      <InputForm
        placeholder="Оставьте свой комментарий..."
        w={700}
        h={'auto'}
        multiline
        helperText={isLimit && `${ERROR_MESSAGES.MAX_SYMBOL} -  ${MAX_LENGTH}`}
        error={isLimit}
        onChange={handleChangeValue}
        inputProps={{ maxLength: MAX_LENGTH }}
      />
    </Stack>
  );
};
