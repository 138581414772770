import { useDaysAndWeekdaysInMonth } from '@/hooks/useDaysAndWeekdaysInMonth';
import { DriverSchedules, Schedules } from '@/interfaces/driver';
import SETTINGS from '@/settings';
import { myFetch } from '@/utils';
import './ScheduleDrivers.css';

import { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Zoom } from '@mui/material';
import { getDaysInMonth } from '@/utils/getDaysInMonth';
import { isDateInThePast } from '@/utils/isDateInThePast';

const classesSelectSchedule: any = {
  Готов: 'is-ready',
  Выходной: 'is-weekend',
  Больничный: 'is-sick',
  Отгул: 'is-time-off',
};

export const ScheduleDrivers = () => {
  const navigate = useNavigate();

  const [verticalIndex, setVerticalIndex] = useState(-1);
  const [datePicker, setDatePicker] = useState(new Date());
  const [driversSchedules, setDriversSchedules] = useState<DriverSchedules[] | null>(null);

  const { days, weekDaysInMonth } = useDaysAndWeekdaysInMonth(datePicker);

  const handleChangeDatePicker = async (date: Date | null) => {
    if (date) {
      setDatePicker(date);
      const selectedYear = date.getFullYear();
      const selectedMonth = date.getMonth();
      const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
      const dayList = new Array(daysInMonth).fill(0).map((_, i) => i + 1);
      try {
        const response = await myFetch(
          `${SETTINGS.SITE_URL}/api/v1/drivers/with-schedules/?month=${selectedMonth + 1}&year=${selectedYear}`,
          {},
          navigate
        );
        if (!response.ok) throw new Error('Ошибка в получении графики');
        const data = await response.json();
        const mod = normalizeArray(data.results, dayList.length, {
          id: 0,
          status: '',
          date_schedule: date,
        });

        setDriversSchedules(mod);
      } catch (error) {
        console.error(error);
      }
    } else {
      setDatePicker(new Date());
    }
  };

  const normalizeArray = (
    arrays: DriverSchedules[],
    requiredLength: number,
    fillValue: Schedules
  ) => {
    return arrays.map((array) => {
      const diff = requiredLength - array.schedules.length;
      if (diff > 0) {
        return { ...array, schedules: [...new Array(diff).fill(fillValue), ...array.schedules] };
      } else {
        return array;
      }
    });
  };

  const updateScheduleStatus = useCallback(
    async (schedule: Schedules, status: string) => {
      await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/drivers/schedules/${schedule.id}/`,
        {
          method: 'PUT',
          body: JSON.stringify({
            id: schedule.id,
            status: status,
            date_schedule: schedule.date_schedule,
          }),
        },
        navigate
      );

      if (driversSchedules) {
        const updatedSchedules = driversSchedules.map((driver) => ({
          ...driver,
          schedules: driver.schedules.map((sch) =>
            sch.id === schedule.id ? { ...sch, status } : sch
          ),
        }));
        setDriversSchedules(updatedSchedules);
      }
    },
    [driversSchedules, navigate]
  );

  useEffect(() => {
    async function fetchData() {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/drivers/with-schedules/?current_month=true`,
        {},
        navigate
      );
      const _json = await res.json();
      const { results } = _json;
      const ds: DriverSchedules[] = results;
      const selectedYear = new Date().getFullYear();
      const selectedMonth = new Date().getMonth();
      const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
      const dayList = new Array(daysInMonth).fill(0).map((_, i) => i + 1);
      const mod = normalizeArray(ds, dayList.length, {
        id: 0,
        status: '',
        date_schedule: new Date(),
      });
      setDriversSchedules(mod);
    }
    fetchData();
  }, [navigate]);

  return (
    <div className="drivers-schedule__table">
      <table className="schedule-table">
        <thead className="table-head">
          <tr className="table-head__rows">
            <tr className="table-head__row-1">
              <td className="table-head__picker">
                <DatePicker
                  selected={datePicker}
                  onChange={handleChangeDatePicker}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
              </td>
              <th className="table-head__name">Сотрудник</th>
            </tr>
            <tr className="table-head__row-2">
              <tr className="table-head__days-box">
                {days.map((day, i) => {
                  return (
                    <th
                      className={`table-head__date ${new Date().getDate() === day && new Date(datePicker).getMonth() === new Date().getMonth() ? 'today-date' : ''}`}
                      key={i}
                    >
                      {day}
                    </th>
                  );
                })}
              </tr>
              <tr className="table-head__weeks-box">
                {weekDaysInMonth.map((week, i) => (
                  <th className="table-head__week" key={i}>
                    {week}
                  </th>
                ))}
                <tr className="table-head__shift-hours-box">
                  <th className="table-head__hours">Часов</th>
                  <th className="table-head__shift">Смены</th>
                </tr>
              </tr>
            </tr>
          </tr>
        </thead>
        <tbody className="table-body">
          {driversSchedules?.map((driver) => {
            if (!driver.schedules) {
              return <></>;
            }
            return (
              <tr className="table-body__row" key={driver.id}>
                <td className="table-body__cell-employee">
                  {`${driver.last_name} ${driver.first_name} ${driver.second_name}`}
                </td>
                <tr className="table-body__status-box">
                  <tr className="table-body__status-box_wrapper">
                    {driver.schedules?.map((sch, index) => {
                      return (
                        <Tooltip
                          key={index}
                          title={sch.status}
                          slots={{
                            transition: Zoom,
                          }}
                          arrow
                        >
                          <td
                            onMouseEnter={() => setVerticalIndex(index)}
                            className={`table-body__status ${!isDateInThePast(sch.date_schedule) ? classesSelectSchedule[sch.status] : 'is-passed'}`}
                          >
                            {sch.status ? (
                              <select
                                title=""
                                disabled={isDateInThePast(sch.date_schedule)}
                                className={`table-body__select ${verticalIndex === index ? 'is-vertical-colored' : ''} ${!isDateInThePast(sch.date_schedule) ? classesSelectSchedule[sch.status] : 'is-passed'}`}
                                value={sch.status}
                                onChange={(event) => {
                                  const val = event.target.value;
                                  updateScheduleStatus(sch, val);
                                }}
                              >
                                <option value="Готов">Г</option>
                                <option value="Больничный">Б</option>
                                <option value="Отгул">О</option>
                                <option value="Выходной">В</option>
                              </select>
                            ) : null}
                          </td>
                        </Tooltip>
                      );
                    })}
                  </tr>
                  <tr className="table-body__shift-hours-box">
                    <td className="table-body__hours">+38</td>
                    <td className="table-body__shift">+4</td>
                  </tr>
                </tr>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
