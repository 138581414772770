import { refreshToken } from './utils/refreshToken';
import SETTINGS from './settings';
import { NavigateFunction } from 'react-router-dom';

interface FetchOptions extends RequestInit {
  headers?: Record<string, string>;
}

export const myFetch = async (
  url: string | URL,
  options: FetchOptions = {},
  navigate: (path: string) => void
): Promise<any> => {
  const token = localStorage.getItem('accessToken');
  const defaultOptions: FetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    cache: 'no-cache',
    ...options,
  };
  let response;
  try {
    response = await fetch(url, defaultOptions);

    if (response.status === 401) {
      // Если получаем 401, пробуем обновить токен
      const newToken = await refreshToken();
      if (newToken) {
        defaultOptions.headers!['Authorization'] = `Bearer ${newToken}`;
        response = await fetch(url, defaultOptions);
      } else {
        throw new Error('Не удалось обновить токен');
      }
    }
    return response;
  } catch (error) {
    console.error('Ошибка запроса', error);
    navigate('/login');
    return response;
  }
};

type ExportFileFatch = (
  page: string,
  navigate: (path: string) => void,
  params: { [key: string]: string },
  att?: string
) => Promise<Response>;

export const exportFileFatch: ExportFileFatch = async (page, navigate, params, att) => {
  const current_url = att ? `/api/v1/${page}/${att}/export_xlsx` : `/api/v1/${page}/export_xlsx`;
  const url = new URL(current_url, SETTINGS.SITE_URL);
  Object.keys(params).forEach((key) => {
    const value = params[key];
    url.searchParams.append(key, value.toString());
  });

  const response = await myFetch(url, { method: 'GET' }, navigate);
  return response;
};

type ExportImageFetchByReq = (
  req_id: number,
  image_id: number,
  navigate: NavigateFunction
) => Promise<Response>;

export const exportImageFetchByReq: ExportImageFetchByReq = async (req_id, image_id, navigate) => {
  const response = await myFetch(
    `${SETTINGS.SITE_URL}/api/v1/reqs/${req_id}/images/${image_id}/download-image/`,
    {},
    navigate
  );
  return response;
};
