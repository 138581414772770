import { ContactPersonObject } from '../interfaces/company';
import { CountKey, ReqForm } from '../interfaces/req';

type STATIC_CLIENT_DATA = {
  id: number;
  label: string;
  value: keyof ContactPersonObject;
};

export const ERROR_MESSAGES = {
  DEFAULT: 'Произошла ошибка, данные не были сохранены.',
  NO_CHANGES: 'Изменения не были внесены',
  NO_DATE: 'Пожалуйста, укажите новую дату',
  MAX_SYMBOL: 'Максимальное количество символов в ячейке с учетом знаков',
};

export const STATUS_CLASSES: any = {
  'Водитель назначен': 'appointed',
  Завершена: 'completed',
  'В работе': 'in-work',
  Новая: 'new',
};

export const VALIDATION_FIELDS = ['address', 'vehicle', 'driver', 'coordinates', 'num'];
export const VALIDATION_ADD_FIELDS: (keyof ReqForm)[] = ['client', 'address_location', 'source'];
export const VALIDATION_REQS_FILTERS: Record<string, CountKey> = {
  ALL: 'count',
  Новая: 'new_count',
  ['Водитель назначен']: 'driver_appointed_count',
  ['В работе']: 'in_work_count',
  Завершена: 'finished_count',
  ['Не оплачен']: 'not_paid_count',
  Оплачен: 'paid_count',
};

export const ROUTE_TYPES = ['Поставил - забрал', 'Поставил', 'Забрал'];
export const CONTAINER_TYPES = ['8', '15', '18', '27', '35'];

export const SOURCE_REQ = ['Заявка по звонку', 'Заявка с сайта'];

export const WEEK_DAYS = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const STATIC_CLIENT_DATA: STATIC_CLIENT_DATA[] = [
  { id: 1, label: 'Контактное лицо', value: 'first_name' },
  { id: 2, label: 'Телефон', value: 'phone' },
  { id: 3, label: 'Электронная почта', value: 'email' },
];

export const SNACKBAR_CONFIG = {
  autoHideDuration: 2000,
  anchorOrigin: { vertical: 'top', horizontal: 'center' } as const,
};
export const DATE_FORMAT_OPTIONS = {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
} as const;
