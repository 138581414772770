import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReqStatus } from '../../interfaces/req';
import { myFetch } from '../../utils';
import SETTINGS from '../../settings';
import { Select } from '../../components/select/Select';
import { cubeType } from '../../params/container';
import { ContainerStat, CubeType, CubeTypeM3 } from '../../interfaces/container';
import { reqStatus } from '../../params/req';
import { SelectChangeEvent } from '@mui/material';
import { getDateFormat } from '../../utils/getDateFormat';
import { DebtorsCompany } from '../../interfaces/company';
import { VehicleStat } from '../../interfaces/vehicle';

interface ReqStat {
  req_count: number;
  req_sum: number;
  req_sum_level?: number;
  month: number;
  year: number;
}

interface ClientWithDebit {
  id: number;
  debit: number;
  inn: string;
  name: string;
}

interface StatContainer {
  num: string;
  status: string;
  volume: number;
  reqs_finished: number;
}

interface StatVehicle {
  license_plate: string;
  reqs_finished: number;
  max_volume: number;
  brand_name: string;
}

type SortDynamicOrder = {
  type: CubeType | '';
  status: ReqStatus | '';
};
const initialSortDynamicOrder: SortDynamicOrder = {
  type: '',
  status: '',
};

type SortState = {
  debtors: 'total_debt' | '-total_debt';
  containers: 'finished_reqs_count' | '-finished_reqs_count';
  vahicles: 'finished_reqs_count' | '-finished_reqs_count';
};
const initialSortState: SortState = {
  debtors: 'total_debt',
  containers: 'finished_reqs_count',
  vahicles: 'finished_reqs_count',
};

const TYPE_CONTAINER: { [key in CubeTypeM3]: CubeType } = {
  ['8м3']: '8',
  ['15м3']: '15',
  ['18м3']: '18',
  ['27м3']: '27',
  ['35м3']: '35',
};
const STATUS_CONTAINNER = {
  ['На базе']: 'on-base',
  ['У клиента']: 'at-client',
  ['На машине']: 'on-vehicles',
};

export default function StatPage() {
  const ss: Array<ReqStat> = [];
  const cwd: Array<ClientWithDebit> = [];
  const sc: Array<StatContainer> = [];
  const sv: Array<StatVehicle> = [];
  const [countsAndSums, setCountsAndSums] = useState({
    finished_count: 0,
    finished_sum: 0,
    debit_companies_count: 0,
    debit_sum: 0,
    driver_online_count: 0,
    driver_count: 0,
    container_at_client_count: 0,
    container_count: 0,
    req_stats: ss,
    clients_not_paid: cwd,
    containers: sc,
    vehicles: sv,
  });
  const [dynamicStats, setDynamicStats] = useState([]);
  const [sortDynamicOrder, setSortDynamicOrder] =
    useState<SortDynamicOrder>(initialSortDynamicOrder);

  const [sortState, setSortState] = useState<SortState>(initialSortState);

  const [debtors, setDebtors] = useState<DebtorsCompany[] | []>([]);
  const [containers, setContainers] = useState<ContainerStat[] | []>([]);
  const [vagicles, setVahicles] = useState<VehicleStat[] | []>([]);

  const handleChangeTypeContainer = (event: SelectChangeEvent) => {
    const changedValue = event.target.value as CubeType;
    setSortDynamicOrder((prevValue) => ({ ...prevValue, type: changedValue }));
  };

  const handleChangeReqStatus = (event: SelectChangeEvent) => {
    const changedValue = event.target.value as ReqStatus;
    setSortDynamicOrder((prevValue) => ({
      ...prevValue,
      status: changedValue,
    }));
  };

  const handleSortDebtorsAmount = () => {
    setSortState((prevValue) => ({
      ...prevValue,
      debtors: prevValue.debtors === 'total_debt' ? '-total_debt' : 'total_debt',
    }));
  };

  const handleSortContainerReq = () => {
    setSortState((prevValue) => ({
      ...prevValue,
      containers:
        prevValue.containers === 'finished_reqs_count'
          ? '-finished_reqs_count'
          : 'finished_reqs_count',
    }));
  };

  const handleSortVehiclesReq = () => {
    setSortState((prevValue) => ({
      ...prevValue,
      vahicles:
        prevValue.vahicles === 'finished_reqs_count'
          ? '-finished_reqs_count'
          : 'finished_reqs_count',
    }));
  };

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/reqs/monthly_stats?container_kind=${
          sortDynamicOrder.type ? `${sortDynamicOrder.type}м3` : ''
        }&status=${sortDynamicOrder.status}`,
        {},
        navigate
      );
      const data = await res.json();
      setDynamicStats(data);
    }
    fetchData();
  }, [sortDynamicOrder, navigate]);

  useEffect(() => {
    async function fetchData() {
      const res = await myFetch(`${SETTINGS.SITE_URL}/api/v1/reqs/stats/`, {}, navigate);
      if (res.status === 200) {
        const d: {
          finished_count: number;
          finished_sum: number;
          debit_companies_count: number;
          debit_sum: number;
          driver_online_count: number;
          driver_count: number;
          container_at_client_count: number;
          container_count: number;
          req_stats: Array<ReqStat>;
          clients_not_paid: Array<ClientWithDebit>;
          containers: Array<StatContainer>;
          vehicles: Array<StatVehicle>;
        } = await res.json();
        let maxSum = 0;
        d.req_stats.forEach((rs) => {
          if (maxSum < rs.req_sum) {
            maxSum = rs.req_sum;
          }
        });
        d.req_stats = d.req_stats.map((rs: ReqStat) => {
          return {
            ...rs,
            req_sum_level: rs.req_sum / (maxSum || 1),
          };
        });
        setCountsAndSums(d);
      }
    }
    fetchData();
  }, [navigate]);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/companies/?ordering=${sortState.debtors}`,
        {},
        navigate
      );
      const data = await res.json();
      setDebtors(data.results);
    }
    fetchData();
  }, [sortState.debtors, navigate]);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/containers/?ordering=${sortState.containers}`,
        {},
        navigate
      );
      const data = await res.json();
      setContainers(data.results);
    }
    fetchData();
  }, [sortState.containers, navigate]);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const res = await myFetch(
        `${SETTINGS.SITE_URL}/api/v1/vehicles/?ordering=${sortState.vahicles}`,
        {},
        navigate
      );
      const data = await res.json();
      setVahicles(data.results);
    }
    fetchData();
  }, [sortState.vahicles, navigate]);

  return (
    <>
      <div className="statistics-page">
        <div className="page-top d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <span className="page-top__title">Показатели</span>
          </div>
          <div className="page-top__right d-flex items-center">
            <div className="page-top__filter d-flex">
              {/*{
                            reqDateFilterItems.map((item: {
                                value: string
                                label: string
                                dayStart: number
                                dayEnd: number
                                withoutDate: boolean
                            }) => {
                                const classList = ["page-top__filter-item", "d-flex items-center", "transition", dateKind === item.value ? "is-active" : ""].join(" ")
                                return <div
                                    key={item.value}
                                    className={classList}
                                    onClick={() => {
                                        setDateKind(item.value);
                                        localStorage.setItem("reqListDateKind", item.value)
                                        if (item.withoutDate) {
                                            localStorage.setItem("reqListStartDate", "0")
                                            localStorage.setItem("reqListEndDate", "0")
                                            setStartDate(new Date(0))
                                            setEndDate(new Date(0))
                                        } else {
                                            const sd = new Date(new Date().getTime() - item.dayEnd * 24 * 60 * 60 * 1000)
                                            localStorage.setItem("reqListStartDate", sd.getTime().toString())
                                            const ed = new Date(new Date().getTime() - item.dayStart * 24 * 60 * 60 * 1000)
                                            localStorage.setItem("reqListEndDate", ed.getTime().toString())
                                            setStartDate(sd);
                                            setEndDate(ed)
                                        }
                                    }}
                                >
                                    {item.label}
                                </div>
                            })
                        }*/}
            </div>

            <div className="page-top__calendar d-flex items-center">
              <div className="page-top__calendar-icon circle-center">
                <span className="icon-calendar"></span>
              </div>
              <>
                {/*<DatePicker
                                selected={startDate}
                                onChange={(date) => {
                                    localStorage.setItem("reqListDateKind", "by_date")
                                    localStorage.setItem("reqListStartDate", date!.getTime().toString())
                                    setDateKind('by_date')
                                    setStartDate(date!)
                                }}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd.MM.YYYY"
                                shouldCloseOnSelect
                                // showYearPicker
                            />
                            &nbsp;-&nbsp;
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => {
                                    setEndDate(date!)
                                    setDateKind('by_date')
                                }}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="dd.MM.YYYY"
                                shouldCloseOnSelect
                                // showYearPicker
                            />*/}
              </>
            </div>

            <div className="page-top__search">
              {/*<TextField
                            size="small"
                            variant="outlined"
                            value={query}
                            onChange={handleChangeQuery}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <span className="icon-search"></span>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{ display: showClearIconQuery }}
                                        onClick={handleClickClearQuery}
                                    >
                                        <span className="icon-close"></span>
                                    </InputAdornment>
                                )
                            }}
                        />*/}
            </div>

            <div className="page-top__dispatcher d-flex items-center">
              <div className="page-top__dispatcher-icon circle-center">
                <span className="icon-settings-b"></span>
              </div>

              <span className="page-top__dispatcher-text d-flex items-center">
                Диспетчер · <span className="transition">{/*{me?.full_name}*/}</span>{' '}
                <span className="icon-angle-down transition"></span>
              </span>

              <div className="dispatcher-menu">
                <div className="dispatcher-menu__inner">
                  <a href="" className="dispatcher-menu__item transition">
                    Личные данные
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Сменить роль
                  </a>
                  <a href="" className="dispatcher-menu__item transition">
                    Выйти
                  </a>
                </div>
              </div>
            </div>

            <NavLink to={'/reqs/add/'} className="page-top__button d-flex items-center transition">
              Новая заявка
            </NavLink>
          </div>
        </div>

        <div className="page-content">
          <div className="statistics-page__top d-flex flex-wrap">
            <div className="statistics-page__details d-flex">
              <div className="statistics-page__details-inner d-flex flex-wrap justify-between">
                <div className="statistics-page__detail d-flex flex-column">
                  <div className="statistics-page__detail-title d-flex items-center justify-between">
                    Завершенных <br />
                    заказов
                    <div className="statistics-page__detail-icon circle-center">
                      <span className="icon-check-circle-black"></span>
                    </div>
                  </div>
                  <div className="statistics-page__detail-text statistics-page__detail-text--green">
                    {countsAndSums.finished_count} завершено
                  </div>
                  <div className="statistics-page__detail-value">
                    {countsAndSums.finished_sum.toLocaleString('Ru-ru')} ₽
                  </div>
                </div>

                <div className="statistics-page__detail d-flex flex-column">
                  <div className="statistics-page__detail-title d-flex items-center justify-between">
                    Дебиторская <br />
                    задолженность
                    <div className="statistics-page__detail-icon circle-center">
                      <span className="icon-money-remove"></span>
                    </div>
                  </div>
                  <div className="statistics-page__detail-text statistics-page__detail-text--red">
                    {countsAndSums.debit_companies_count} компаний
                  </div>
                  <div className="statistics-page__detail-value">
                    {countsAndSums.debit_sum.toLocaleString('Ru-ru')} ₽
                  </div>
                </div>

                <div className="statistics-page__detail d-flex items-center">
                  <div className="statistics-page__detail-column d-flex flex-column">
                    <span className="statistics-page__detail-title statistics-page__detail-title--small">
                      Контейнеров у клиентов
                    </span>
                    <div className="statistics-page__detail-value d-flex items-end">
                      {countsAndSums.container_at_client_count}
                      &nbsp;<span>из {countsAndSums.container_count}</span>
                    </div>
                  </div>
                  <div className="statistics-page__detail-icon circle-center">
                    <span className="icon-cube-black"></span>
                  </div>
                </div>

                <div className="statistics-page__detail d-flex items-center">
                  <div className="statistics-page__detail-column d-flex flex-column">
                    <span className="statistics-page__detail-title statistics-page__detail-title--small">
                      Водителей на линии
                    </span>
                    <div className="statistics-page__detail-value d-flex items-end">
                      {countsAndSums.driver_online_count}
                      &nbsp;<span>из {countsAndSums.driver_count}</span>
                    </div>
                  </div>
                  <div className="statistics-page__detail-icon circle-center">
                    <span className="icon-profile-2user"></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="statistics-page__dynamic d-flex">
              <div className="statistics-page__dynamic-inner d-flex flex-column">
                <div className="statistics-page__dynamic-title d-flex items-center">
                  Динамика по заявкам
                  <div className="statistics-page__dynamic-filters d-flex">
                    <Select
                      label="Тип контейнера"
                      value={sortDynamicOrder.type}
                      itemList={cubeType}
                      onChange={handleChangeTypeContainer}
                    />
                    <Select
                      label="Статус"
                      value={sortDynamicOrder.status}
                      itemList={reqStatus}
                      onChange={handleChangeReqStatus}
                    />
                  </div>
                </div>

                <div className="chart d-flex">
                  {dynamicStats.map((rs: ReqStat) => {
                    const formatedDate = getDateFormat(`${rs.month}`, {
                      month: 'short',
                      year: 'numeric',
                    });

                    return (
                      <div
                        key={`chart-${rs.year}-${rs.month}`}
                        className="chart-item d-flex flex-column"
                      >
                        <div className="chart-item__top d-flex">
                          <div
                            className="chart-item__top-orders"
                            style={{ height: `${100 * rs.req_sum_level!}%` }}
                          ></div>
                        </div>
                        <div className="chart-item__bottom d-flex">
                          <span className="chart-item__bottom-month">{formatedDate}</span>
                          <span className="chart-item__bottom-price">
                            {rs.req_sum.toLocaleString('ru-RU')}
                          </span>
                          <div className="chart-item__bottom-orders d-flex items-center justify-center">
                            {rs.req_count} заказов
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="statistics-page__tables d-flex flex-wrap">
            <div className="statistics-page__table debtors-table">
              <div className="statistics-page__table-title">Должники</div>

              <table className="table">
                <thead className="table__top">
                  <tr className="table__top-tr">
                    <th className="table__top-th th-id">Id</th>
                    <th className="table__top-th th-name">Наименование</th>
                    <th className="table__top-th th-inn">ИНН</th>
                    <th className="table__top-th th-amount">
                      Сумма долга{' '}
                      <span
                        className={`icon-angle-down transition cursor ${
                          sortState.debtors === 'total_debt' ? 'sorted_up' : ''
                        }`}
                        onClick={handleSortDebtorsAmount}
                      ></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {debtors.map((cwd: DebtorsCompany) => {
                    return (
                      <tr key={cwd.id} className="table__row">
                        <td className="table__td td-id">
                          <div className="table__td-inner d-flex items-center">{cwd.id}</div>
                        </td>
                        <td className="table__td td-name">
                          <div className="table__td-inner d-flex items-center">{cwd.name}</div>
                        </td>
                        <td className="table__td td-inn">
                          <div className="table__td-inner d-flex items-center">{cwd.inn}</div>
                        </td>
                        <td className="table__td td-amount">
                          <div className="table__td-inner d-flex items-center">
                            {Number(cwd.total_debt).toLocaleString('ru-Ru')} ₽
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="statistics-page__table containers-table">
              <div className="statistics-page__table-title">Контейнеры</div>

              <table className="table">
                <thead className="table__top">
                  <tr className="table__top-tr">
                    <th className="table__top-th th-status">Статус</th>
                    <th className="table__top-th th-type">Тип контейнера</th>
                    <th className="table__top-th th-number">Номер конт.</th>
                    <th className="table__top-th th-applications">
                      Заявок
                      <span
                        className={`icon-angle-down transition cursor ${
                          sortState.containers === 'finished_reqs_count' ? 'sorted_up' : ''
                        }`}
                        onClick={handleSortContainerReq}
                      ></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {containers.map((c, i) => {
                    return (
                      <tr className="table__row" key={i}>
                        <td className="table__td td-status">
                          <div className="table__td-inner d-flex items-center">
                            <div
                              className={`td-status__block ${
                                STATUS_CONTAINNER[c.status]
                              } d-flex items-center`}
                            >
                              {/* classes for statuses: at-client - У клиента, on-base - На базе */}
                              {c.status}
                            </div>
                          </div>
                        </td>
                        <td className="table__td td-type">
                          <div className="table__td-inner d-flex items-center">
                            {TYPE_CONTAINER[c.kind]} кубов
                          </div>
                        </td>
                        <td className="table__td td-number">
                          <div className="table__td-inner d-flex items-center">{c.num}</div>
                        </td>
                        <td className="table__td td-applications">
                          <div className="table__td-inner d-flex items-center">
                            {c.finished_reqs_count} закрыто
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="statistics-page__table cars-table">
              <div className="statistics-page__table-title">Автомашины</div>

              <table className="table">
                <thead className="table__top">
                  <tr className="table__top-tr">
                    <th className="table__top-th th-name">Наименование</th>
                    <th className="table__top-th th-applications">
                      Заявок{' '}
                      <span
                        className={`icon-angle-down transition cursor ${
                          sortState.vahicles === 'finished_reqs_count' ? 'sorted_up' : ''
                        }`}
                        onClick={handleSortVehiclesReq}
                      ></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vagicles.map((v) => {
                    return (
                      <tr key={v.license_plate} className="table__row">
                        <td className="table__td td-name">
                          <div className="table__td-inner d-flex items-center">
                            {v.brand?.name ?? 'Неизв'} · {v.max_volume} кубов · {v.license_plate}
                          </div>
                        </td>
                        <td className="table__td td-applications">
                          <div className="table__td-inner d-flex items-center">
                            {v.finished_reqs_count} закрыто
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
