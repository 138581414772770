import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { myFetch } from '../../utils';
import SETTINGS from '../../settings';

export default function DriverInstructionsPage() {
  const { pageSecret } = useParams();
  const navigate = useNavigate();

  const [text, setText] = useState(<></>);

  useEffect(() => {
    myFetch(`${SETTINGS.SITE_URL}/api/v1/drivers/by-page-secret/${pageSecret}/`, {}, navigate).then(
      async (response) => {
        if (response.status === 404) {
          setText(<h1>404. Не найдено</h1>);
          return;
        }
        const driver = await response.json();
        const t = (
          <>
            <h1>Инструкция по работе с ботом</h1>
            <p>{`Здравствуйте, ${driver.first_name} ${driver.last_name}`}</p>
            <p>
              Перейдите по{' '}
              <a href={`https://t.me/${SETTINGS.BOT_NAME}?start=${driver.bot_secret}`}>ссылке</a>
            </p>
          </>
        );
        setText(t);
      }
    );
  }, [navigate, pageSecret]);
  return <>{text}</>;
}
