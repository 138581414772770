import { SelectOutLabel } from '../../ui/mui/Select/SelectOutLabel';
import { ROUTE_TYPES } from '../../constants/constants';
import { useAddFormContext } from '../../hooks/Context/useAddFormContext';

export const RouteType = () => {
  const { updateFormData } = useAddFormContext();
  return (
    <SelectOutLabel
      list={ROUTE_TYPES}
      label="Выберите тип маршрута"
      w={225}
      defaultValue={'Поставил - забрал'}
      onChangeValue={(value) => updateFormData('kind')(value)}
    />
  );
};
