import { NavLink } from 'react-router-dom';

const data = [
  {
    name: 'Карта заявок',
    url: '/',
    iconClass: 'global',
  },
  {
    name: 'Заявки',
    url: '/reqs/',
    iconClass: 'layer',
  },
  {
    name: 'Автомашины',
    url: '/vehicles/',
    iconClass: 'truck-black',
  },
  {
    name: 'Компании',
    url: '/companies/',
    iconClass: 'briefcase',
  },
  {
    name: 'Инвентаризация',
    url: '/containers/',
    iconClass: 'task-square',
  },
  {
    name: 'Водители',
    url: '/drivers/',
    iconClass: 'profile-2user',
  },
  {
    name: 'Показатели',
    url: '/stats/',
    iconClass: 'graph',
  },
  {
    name: 'Логи',
    url: '/logs/',
    iconClass: 'layer',
  },
];

export default function Sidebar() {
  // const [open, setOpen] = useState(false)
  // const width = open ? 250 : 65
  return (
    <>
      <aside className="aside">
        <div className="aside-wrapper">
          <div className="aside-logo d-flex">
            <div className="aside-logo__image d-flex">
              <img src="/assets/images/logo.svg" alt="logo image" />
            </div>

            <img src="/assets/images/logo-text.svg" alt="" className="aside-logo__logo-text" />
          </div>

          <div className="aside-menu-container">
            <ul role="menubar" className="aside-menu">
              {data.map((datum) => {
                return (
                  <li key={datum.url} role="menuitem" className="aside-menu__item">
                    <NavLink to={datum.url} className="transition d-flex items-center">
                      <div className="aside-menu__item-icon d-flex">
                        <span className={`icon-${datum.iconClass} transition`}></span>
                      </div>

                      <span className="aside-menu__item-title d-flex items-center transition">
                        {datum.name}
                      </span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
}
