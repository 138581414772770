export class ServiceContainer {
  private services: { [key: string]: any } = {};

  register<T>(key: string, service: T): void {
    this.services[key] = service;
  }

  resolve<T>(key: string): T {
    const service = this.services[key];
    if (!service) {
      throw new Error(`Service not found: ${key}`);
    }
    return service;
  }
}

export const container = new ServiceContainer();
