import { useEffect, useState } from 'react';
import SETTINGS from '../../settings';
import MyForm from '../../components/MyForm';
import { exportFileFatch, myFetch } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import cEditFields from '../../form-fields/c-edit';
import { Req } from '../../interfaces/req';
import { CONTAINER_STATUS_MAP } from '../../params/container';
import { Box } from '@mui/material';
import { Select } from '../../components/select/Select';
import { downloadFile } from '../../utils/downloadFile';

export default function ContainerEdit() {
  // const reqId = props.match.params.id
  const navigate = useNavigate();
  const { cId } = useParams();
  const baseC: any = {};
  const [c, setC] = useState(baseC);
  const crs: Req[] = [];
  const [containerReqs, setContainerReqs] = useState(crs);
  const baseErrors: any = {};
  const [errors, setErrors] = useState(baseErrors);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseContainer = await myFetch(
          `${SETTINGS.SITE_URL}/api/v1/containers/${cId}/`,
          {},
          navigate
        );
        const container = await responseContainer.json();
        const ownerFetch = container.is_client_owned
          ? myFetch(`${SETTINGS.SITE_URL}/api/v1/companies/${container.owner}/`, {}, navigate)
          : Promise.resolve({ json: () => Promise.resolve({ name: 'Чистый мир' }) });

        const [ownerResponse, reqsResponse] = await Promise.all([
          ownerFetch,
          myFetch(`${SETTINGS.SITE_URL}/api/v1/reqs/by-containers/${cId}/`, {}, navigate),
        ]);
        const owner = await ownerResponse.json();
        const modContainer = { ...container, owner };
        setC(modContainer);
        setContainerReqs(await reqsResponse.json());
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [cId, navigate]);

  const handleExportFile = async () => {
    const params = {
      container_id: cId!,
    };

    const response = await exportFileFatch('reqs', navigate, params);
    try {
      await downloadFile(response, `container_reqs${cId}`, 'xlsx');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="container-page d-flex flex-wrap">
        <div className="page-top page-top--black d-flex items-center">
          <div className="page-top__left d-flex items-center">
            <div className="page-top__back circle-center" onClick={() => navigate(-1)}>
              <span className="icon-angle-left"></span>
            </div>
            <span className="page-top__title">{`Контейнер ID ${c.id}`}</span>
            <div
              className={`page-top__status ${CONTAINER_STATUS_MAP[c.status]} d-flex items-center`}
            >
              {c.status}
            </div>
          </div>
          <div className="page-top__right d-flex items-center">
            <div className="page-top__block d-flex items-center">
              <div className="page-top__block-icon circle-center">
                <span className="icon-check-circle"></span>
              </div>
              Заявок завершено <b>· {c.count_finished}</b>
            </div>
          </div>
        </div>

        <div className="container-page__content">
          <MyForm
            fields={cEditFields}
            method="PUT"
            returnUrl={`/containers/`}
            formUrl={`${SETTINGS.SITE_URL}/api/v1/containers/${cId}/`}
            state={c}
            setState={setC}
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <div className="container-page__table">
          <div className="container-pages__table-top d-flex justify-between items-center">
            <h2 className="form__title">Заявки по контейнеру</h2>
            <Box style={{ width: '101px' }}>
              <Select
                onChange={handleExportFile}
                itemList={['Экспорт в xlsx']}
                value={''}
                label={'Действия'}
              />
            </Box>
          </div>

          <table className="applications-table table">
            <thead className="table__top">
              <tr className="table__top-tr">
                <th className="table__top-th th-id">
                  <div className="d-flex items-center">
                    <label className="table__checkbox">
                      <input type="checkbox" className="table__checkbox-input" />
                      <div className="table__checkbox-block d-flex block-center transition">
                        <span className="icon-check"></span>
                      </div>
                    </label>
                    id
                  </div>
                </th>
                <th className="table__top-th th-status">Статус</th>
                <th className="table__top-th th-payment">Оплата</th>
                <th className="table__top-th th-client">Клиент</th>
                <th className="table__top-th th-address">Адрес объекта</th>
                <th className="table__top-th th-phone">Номер телефона</th>
                <th className="table__top-th th-driver">Водитель</th>
                <th className="table__top-th th-car">Автомашина</th>
                <th className="table__top-th th-date">Дата заявки</th>
                <th className="table__top-th th-type">Тип заявки</th>
              </tr>
            </thead>
            <tbody>
              {containerReqs.map((req: Req) => {
                return (
                  <tr className="table__row" key={req.id}>
                    <td className="table__td td-id">
                      <div className="table__td-inner d-flex items-center">
                        <label className="table__checkbox">
                          <input type="checkbox" className="table__checkbox-input" />
                          <div className="table__checkbox-block d-flex block-center transition">
                            <span className="icon-check"></span>
                          </div>
                        </label>
                        {req.id}
                      </div>
                    </td>
                    <td className="table__td td-status">
                      <div className="table__td-inner d-flex items-center">
                        <div className="td-status__block in-work d-flex items-center">
                          {/* classes for statuses: in-work - В работе, completed - Завершен */}
                          {req.status}
                        </div>
                      </div>
                    </td>
                    <td className="table__td td-payment">
                      <div className="table__td-inner d-flex items-center">
                        <div className="td-payment__block unpaid d-flex items-center">
                          {/* classes for statuses: unpaid - Неоплачен, paid - Оплачен */}
                          {req.payment_status}
                        </div>
                      </div>
                    </td>
                    <td className="table__td td-client">
                      <div className="table__td-inner d-flex items-center">{req.client?.name}</div>
                    </td>
                    <td className="table__td td-address">
                      <div className="table__td-inner d-flex items-center">
                        {req.address?.location}
                      </div>
                    </td>
                    <td className="table__td td-phone">
                      <div className="table__td-inner d-flex items-center">
                        <a href="tel:+79434340563">{`+7${req.client?.phone}`}</a>
                      </div>
                    </td>
                    <td className="table__td td-driver">
                      <div className="table__td-inner d-flex items-center">
                        {req.author?.full_name}
                      </div>
                    </td>
                    <td className="table__td td-car">
                      <div className="table__td-inner d-flex items-center">
                        {req.vehicle?.license_plate}
                      </div>
                    </td>
                    <td className="table__td td-date">
                      <div className="table__td-inner d-flex items-center">{req.req_date}</div>
                    </td>
                    <td className="table__td td-type">
                      <div className="table__td-inner d-flex items-center">{req.kind}</div>
                    </td>
                    <a href="" className="table__move circle-center transition">
                      <span className="icon-arrow-right transition"></span>
                    </a>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
