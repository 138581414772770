import { Stack } from '@mui/material';
import { Title } from '../../../../../ui/mui/Title/Title';
import { Group } from '../../../../../ui/mui/Group/Group';
import { ViewingField } from '../../../../../ui/Fields/ViewingField';
import { FC } from 'react';
import { Company, CompanyObject } from '../../../../../interfaces/company';
import { Dispatcher } from '../../../../../interfaces/dispatcher';

type ClientSectionProps = {
  client: Company | undefined;
  client_object: CompanyObject | undefined;
  source: string;
  dispatcher: Dispatcher;
};
export const ClientSection: FC<ClientSectionProps> = ({
  client,
  client_object,
  source,
  dispatcher,
}) => {
  return (
    <Stack gap={2}>
      <Title variant="h4">Клиент</Title>
      <Group gap={10}>
        <ViewingField label="Клиент" value={client?.name} w={20} />
        <ViewingField label="Объекты" value={client_object?.address} w={79} />
      </Group>
      <Group gap={10}>
        <ViewingField
          label="Контактное лицо"
          value={client_object?.contact_persons[0]?.first_name}
          w={20}
        />
        <ViewingField
          label="Номер телефона"
          value={client_object?.contact_persons[0]?.phone}
          w={18}
        />
        <ViewingField
          label="Электронная почта"
          value={client_object?.contact_persons[0]?.email}
          w={16}
        />
        <ViewingField label="Источник заявки" value={source} w={14} />
        <ViewingField label="Заявку создал(а)" value={dispatcher?.full_name} w={10} disabled />
      </Group>
    </Stack>
  );
};
