import { useState } from 'react';
import SETTINGS from '../../settings';
import MyForm from '../../components/MyForm';
import driverAddFields from '../../form-fields/driver-add';

export default function DriverAdd() {
  const baseDriver: any = {};
  const [driver, setDriver] = useState(baseDriver);
  const baseErrors: any = {};
  const [errors, setErrors] = useState(baseErrors);

  return (
    <>
      <MyForm
        fields={driverAddFields}
        method="POST"
        returnUrl={`/drivers/`}
        formUrl={`${SETTINGS.SITE_URL}/api/v1/drivers/`}
        state={driver}
        setState={setDriver}
        errors={errors}
        setErrors={setErrors}
      />
    </>
  );
}
