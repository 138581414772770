export const isDateInThePast = (date: Date) => {
  const today = new Date();
  const comparisonDate = new Date(date);

  today.setHours(0, 0, 0, 0);
  comparisonDate.setHours(0, 0, 0, 0);

  if (today <= comparisonDate) {
    return false;
  } else {
    return true;
  }
};
