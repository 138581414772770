import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

type SaveCancelProps = {
  onSave?: () => void;
  onCancel?: () => void;
  formId?: string;
};
export const SaveCancel: FC<SaveCancelProps> = ({ onSave, onCancel, formId }) => {
  const navigate = useNavigate();
  return (
    <div className="form__buttons d-flex">
      <button
        type={formId ? 'submit' : 'button'}
        form={formId}
        className="form__buttons-btn form__buttons-btn--primary d-flex items-center transition"
        onClick={onSave}
      >
        Сохранить
      </button>
      <button
        type="button"
        className="form__buttons-btn form__buttons-btn--cancel d-flex items-center transition"
        onClick={onCancel ? onCancel : () => navigate(-1)}
      >
        Отменить
      </button>
    </div>
  );
};
